import moment from "moment";
import React, { useEffect, useState } from "react";
// @ts-ignore
import DatePicker from "react-datepicker";

export interface ShowInterface {
  title: string;
  description: string;
  date: string;
  eventType: "liveparty" | "liveshow";
  showPrivacy: "public" | "private";
  showOnlineUsers: "yes" | "no";
  allowChat: "yes" | "no";
  canUseGuestMode: "yes" | "no";
  image: File;
  videoLink: string;
}

// @ts-ignore
import Image from "next/image";
import { useRouter } from "next/router";
import { useSelector } from "react-redux";

import EventType from "./eventType";

export default function StepOne({
  saveData,
  isEdit,
  setSelectedGoLive,
  selectedGoLive,
  hasTouched,
}: {
  saveData: (data: any) => void;
  isEdit: boolean;
  setSelectedGoLive: (val: boolean) => void;
  selectedGoLive?: boolean;
  hasTouched?: (val: boolean) => void;
}) {
  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");
  const router = useRouter();
  const { id } = router.query;

  const [isLiveparty, setIsLiveparty] = useState(true);

  const [postDate, setPostDate] = useState(
    // @ts-ignore
    moment(new Date()).add(0, "days")._d
  );

  useEffect(() => {
    saveData({
      title: name,
      description: desc,
      start_date: postDate,
      eventType: isLiveparty ? "liveparty" : "liveshow",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, desc, postDate, isLiveparty]);

  const savedShow = useSelector((state: any) => state.show.savedShow);

  useEffect(() => {
    if (savedShow && !name) {
      setName(savedShow.title);
      setDesc(savedShow.description);
      setPostDate(moment(savedShow.start_date).toDate());
      setIsLiveparty(savedShow.eventType === "liveparty");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedShow]);

  return (
    <div className="flex w-full flex-col justify-center gap-6 md:w-full md:justify-start md:pr-4">
      <form className="flex w-full flex-col pt-4 gap-6">
        <div className="mb-2 flex w-full flex-col gap-1 md:mb-0">
          <label className="font-medium" htmlFor="name">
            Show Name
          </label>
          <input
            type="text"
            name="name"
            id="name"
            placeholder="Enter Show Name"
            value={name}
            maxLength={30}
            onChange={(e) => setName(e.target.value)}
            className="common-input input-white h-[50px] w-full placeholder:text-[#7F82BA]"
          />
        </div>
        <span className="-mb-2">When are you going Live?</span>
        <SchedulePicker
          postDate={postDate}
          setPostDate={setPostDate}
          isEdit={!!id || isEdit}
          setSelectedGoLive={setSelectedGoLive}
          selectedGoLive={selectedGoLive}
          hasTouched={hasTouched}
        />
      </form>

      <form className="mb-4 flex w-full flex-col gap-6 md:mb-0">
        <div className="flex flex-col gap-1">
          <label className="font-medium" htmlFor="desc">
            Show Description
          </label>
          <textarea
            name="desc"
            id="desc"
            value={desc}
            onChange={(e) => setDesc(e.target.value)}
            placeholder="Enter Show Description"
            className="common-input input-white h-[60px] w-full placeholder:text-[#7F82BA]"
          />
        </div>
      </form>
    </div>
  );
}

export function SchedulePicker({
  postDate,
  setPostDate,
  isEdit,
  setSelectedGoLive,
  selectedGoLive,
  hasTouched,
}: {
  postDate: any;
  setPostDate: (date: any) => void;
  isEdit?: boolean;
  setSelectedGoLive: (val: boolean) => void;
  selectedGoLive?: boolean;
  hasTouched?: (val: boolean) => void;
}) {
  const [selectedType, setSelectedType] = useState("Go Live Now");

  useEffect(() => {
    if (selectedGoLive && new Date(postDate) < new Date()) {
      setSelectedType("Go Live Now");
      return;
    }
    if (isEdit) {
      // if postDate is less than now, we update postDate to now + 5 mins
      if (new Date(postDate) < new Date()) {
        // @ts-ignore
        setPostDate(moment(new Date()).add(5, "minutes")._d);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, postDate]);

  return (
    <div className="flex w-full flex-col gap-6">
      <div className="flex items-center justify-start gap-2 md:w-full md:gap-6">
        <OneScheduleBlock
          text="Go Live Now"
          isActive={selectedType === "Go Live Now"}
          select={() => {
            setSelectedType("Go Live Now");
            // @ts-ignore
            setPostDate(moment(new Date()).add(0, "days")._d);
            setSelectedGoLive(true);
            hasTouched && hasTouched(true);
          }}
        />
        <OneScheduleBlock
          text="Go Live Later"
          isActive={selectedType === "Go Live Later"}
          select={() => {
            setSelectedType("Go Live Later");
            // now + 1 day
            // @ts-ignore
            setPostDate(moment(new Date()).add(1, "days")._d);
            setSelectedGoLive(false);
            hasTouched && hasTouched(true);
          }}
        />
      </div>

      {selectedType === "Go Live Later" && (
        <div className="-mt-1 flex w-full flex-col gap-2">
          <label htmlFor="date">Start Date</label>
          <DatePicker
            selected={postDate}
            onChange={(date: any) => {
              setPostDate(date);
              hasTouched && hasTouched(true);
            }}
            onKeyDown={(e: any) => e.preventDefault()}
            dateFormat="MMM d, yyyy h:mm aa"
            minDate={moment().add(1, "days").toDate()}
            placeholderText="Date"
            fixedHeight
            showTimeSelect
          />
        </div>
      )}
    </div>
  );
}

export function OneScheduleBlock({
  text,
  isActive,
  select,
}: {
  text: string;
  isActive: boolean;
  select: (text: string) => void;
}) {
  const switchSVG = () => {
    switch (text) {
      case "Go Live Now":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <line x1="10" x2="14" y1="2" y2="2"></line>
            <line x1="12" x2="15" y1="14" y2="11"></line>
            <circle cx="12" cy="14" r="8"></circle>
          </svg>
        );
      case "WhatsApp":
        return (
          <Image
            src="/assets/icons/whatsapp.svg"
            width={24}
            height={24}
            alt="Whatsapp"
          />
        );
      case "Go Live Later":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
            <line x1="16" y1="2" x2="16" y2="6"></line>
            <line x1="8" y1="2" x2="8" y2="6"></line>
            <line x1="3" y1="10" x2="21" y2="10"></line>
            <path d="M8 14h.01"></path>
            <path d="M12 14h.01"></path>
            <path d="M16 14h.01"></path>
            <path d="M8 18h.01"></path>
            <path d="M12 18h.01"></path>
            <path d="M16 18h.01"></path>
          </svg>
        );
      case "My Website":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="h-6 w-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 21a9.004 9.004 0 008.716-6.747M12 21a9.004 9.004 0 01-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 017.843 4.582M12 3a8.997 8.997 0 00-7.843 4.582m15.686 0A11.953 11.953 0 0112 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0121 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0112 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 013 12c0-1.605.42-3.113 1.157-4.418"
            />
          </svg>
        );

      default:
        return "";
    }
  };
  return (
    <div
      onClick={() => {
        select(text);
      }}
      className={`schedule-card min-w-1/2 flex w-1/2 cursor-pointer items-center justify-between gap-1 rounded-md border-[1px] bg-white-50 px-2 py-3 ${
        isActive
          ? "border-[#104553] text-[#104553]"
          : "border-gray-300 text-gray-300"
      }`}
    >
      <div className="flex items-center gap-2">
        {switchSVG()}
        <span className="md:text-md text-xs">{text}</span>
      </div>

      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill={isActive ? "#104553" : "none"}
        className="h-6 w-6"
      >
        <path
          fillRule="evenodd"
          d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
          clipRule="evenodd"
        />
      </svg>
    </div>
  );
}
