// @ts-nocheck
import React, { useEffect, useState, useMemo } from "react";
import { useRouter } from "next/router";
import { useSelector } from "react-redux";
import Seo from "components/common/seo";
import { Spinner } from "components/common/spinner";

const withAuth = (WrappedComponent) => {
  return (props) => {
    const [hasMounted, setHasMounted] = useState(false);
    const router = useRouter();
    const { shopId } = router.query;

    const userProfile = useSelector((state: any) => state.auth.userProfile);

    useEffect(() => {
      setHasMounted(true);
    }, []);

    const shouldRenderSpinner = useMemo(() => {
      return !hasMounted || userProfile === undefined || !shopId;
    }, [hasMounted, userProfile, shopId]);

    useEffect(() => {
      if (!router?.isReady) return;
      if (
        !userProfile ||
        (userProfile?.stores && userProfile?.stores[0]?.storeId !== shopId)
      ) {
        router.replace("/");
      }
    }, [router, userProfile]);

    if (shouldRenderSpinner) {
      return (
        <main className="flex h-screen w-screen items-center justify-center">
          <Seo title="Tranzact With Any Business Using LiveStream Shopping" />
          <Spinner />
        </main>
      );
    }

    return <WrappedComponent {...props} />;
  };
};

export default withAuth;
