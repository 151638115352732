import Image from "next/image";
import { useSession } from "next-auth/react";
import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { setUserProfile } from "redux/auth/authSlice";
import { useUpdateUserProfileMutation } from "services/baseApiSlice";

import notification from "../../components/common/notification";

export interface UserProfile {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  dateOfBirth: string;
  email: string;
  address: string;
  idVerificationType: string;
  idDocumentURL: string;
  stores: any;
}

export const MAX_FILE_SIZE = 5000000; // 5MB

export interface ShopProfile {
  businessName: string;
  businessUsername: string;
  businessAddress: string;
  postalCode: string;
  city: string;
  street: string;
  country: string;
  state: string;
  cacRegNumber: string;
  cacCertificateURL: string;
  tinNumber: string;
  tinCertificateURL: string;
  currentSaleChannel: string[];
  facebookPageURL: string;
  twitterPageURL: string;
  instagramPageURL: string;
  websiteURL: string;
  userId: string;
  isActive: boolean;
  completedStepsArray: string[];
  _id: string;
  verificationStatus: string;
  storeLogoURL: string;
  whatsappPhone: string;
  businessPhone: string;
  storeLogoFileId: string;
  storeCurrency: string;
}

export const formatEmail = (email: string) => {
  // remove . + @ from email
  return email.replace(/\.|\+/g, "").replace("@", "");
};

export default function PersonalDetailsUpdate() {
  const { data: session } = useSession();

  const [ProfilePic, setProfilePic] = useState<any[]>([]);

  const {
    getRootProps,
    getInputProps,
    fileRejections: profileRejections,
  } = useDropzone({
    accept: {
      "image/jpeg": [],
      "image/png": [],
      "image/jpg": [],
    },
    maxSize: MAX_FILE_SIZE,
    onDrop: async (acceptedFiles) => {
      const renamedAcceptedFiles = acceptedFiles.slice(0, 1).map(
        (file) =>
          new File([file], `${new Date()}_${file.name}`, {
            type: file.type,
          })
      );
      if (renamedAcceptedFiles[0]) {
        const newFile = renamedAcceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );

        setProfilePic([...ProfilePic, ...newFile]);
      }
    },
  });

  useEffect(() => {
    if (profileRejections?.length > 0) {
      notification({
        status: "error",
        message:
          "Unsupported image format or size. Please upload PNG, JPEG or JPG. Maximum size of 5mb",
        autoClose: 10000,
      });
    }
  }, [profileRejections]);

  const removeProfile = () => {
    setProfilePic([]);
  };

  const imageExists = true;

  const userProfile = useSelector((state: any) => state.auth.userProfile);
  const businessProfile = useSelector(
    (state: any) => state.auth.businessProfile
  ) as ShopProfile;

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");

  const [userProfilePicture, setUserProfilePicture] = useState("");

  useEffect(() => {
    if (userProfile?.firstName) {
      setFirstName(userProfile.firstName);
    }
    if (userProfile?.lastName) {
      setLastName(userProfile.lastName);
    }
    if (userProfile?.email) {
      setEmail(userProfile.email);
    }
    if (userProfile?.phoneNumber) {
      setPhone(userProfile.phoneNumber);
    }
    if (userProfile?.address) {
      setAddress(userProfile.address);
    }
    if (userProfile?.profilePictureURL) {
      setUserProfilePicture(userProfile.profilePictureURL);
    }
  }, [userProfile]);

  const [enableButton, setEnableButton] = useState(false);

  const checkFields = () => {
    if (
      userProfile?.firstName !== firstName ||
      userProfile?.lastName !== lastName ||
      userProfile?.email !== email ||
      (userProfile?.phoneNumber ?? "") !== phone ||
      (userProfile?.address ?? "") !== address ||
      ProfilePic.length > 0
    ) {
      setEnableButton(true);
    } else {
      setEnableButton(false);
    }
  };

  useEffect(() => {
    checkFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    firstName,
    lastName,
    email,
    phone,
    address,
    ProfilePic,
    userProfile,
  ]);

  const [saveUserInfo, { isLoading }] = useUpdateUserProfileMutation();
  const dispatch = useDispatch();

  const saveUserInfoHandler = async () => {
    // only save fields that have changed
    const objData = {
      firstName: userProfile?.firstName !== firstName ? firstName : undefined,
      lastName: userProfile?.lastName !== lastName ? lastName : undefined,
      email: userProfile?.email !== email ? email : undefined,
      phoneNumber: userProfile?.phoneNumber !== phone ? phone : undefined,
      address: userProfile?.address !== address ? address : undefined,
    };
    // form data for all fields in obj. Adding the images to the form data
    const obj = new FormData();
    Object.keys(objData).forEach((key) => {
      // @ts-ignore
      if (objData[key]) {
        // @ts-ignore
        obj.append(key, objData[key]);
      }
    });
    if (ProfilePic.length > 0) {
      obj.append("files", ProfilePic[0]);
      obj.append("firstField", "profilePicture");
      if (userProfile?.profilePictureFileId?.length > 0) {
        obj.append("deleteProfilePicture", userProfile?.profilePictureFileId);
      }
    }
    obj.append("model", "users");
    obj.append("folder", `users/${formatEmail(email)}/`);
    const userId = userProfile?._id;
    await saveUserInfo({ obj, userId })
      .unwrap()
      .then((payload: any) => {
        dispatch(setUserProfile(payload));
        notification({
          status: "success",
          message: "Profile updated successfully",
        });
      })
      .catch((error) => {
        notification({
          status: "error",
          message: error.data.message,
        });
      });
  };

  return (
    <div className="relative flex w-full flex-col-reverse justify-between pb-32 xl:flex-row xl:gap-16 xl:pb-8">
      {/* editable details to be shown: name, email, phone number, address, profile picture, password */}
      <form className="mt-4 flex w-full max-w-[650px] flex-col gap-4">
        <div className="flex flex-col gap-4 lg:flex-row">
          <div className="flex flex-col gap-2 lg:w-1/2">
            <label htmlFor="name">First Name</label>
            <input
              type="text"
              className="common-input h-[50px]"
              name="fname"
              id="fname"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>
          <div className="flex flex-col gap-2 lg:w-1/2">
            <label htmlFor="name">Last Name</label>
            <input
              type="text"
              className="common-input h-[50px]"
              name="lname"
              id="lname"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
        </div>

        <div className="flex flex-col gap-2">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            name="email"
            className="common-input h-[50px]"
            readOnly
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            id="email"
          />
        </div>

        <button
          onClick={(e) => {
            e.preventDefault();
            saveUserInfoHandler();
          }}
          disabled={!enableButton || isLoading}
          className="mt-8 rounded-sm bg-[#104553] px-4 py-2 font-bold text-white-50 disabled:opacity-50 md:mx-auto md:w-[400px]"
        >
          {isLoading ? "Saving..." : "Save Personal Info"}
        </button>
      </form>

      <div className="flex w-full items-center justify-center gap-8 text-center lg:mt-4 lg:w-auto lg:flex-col lg:justify-between xl:absolute xl:top-[-85px] xl:right-0">
        <div className="flex flex-col gap-4">
          {ProfilePic.length > 0 || imageExists ? (
            <div className="relative mx-auto mb-8 h-[100px] w-[100px] rounded-full border-[1px] border-[#e5e5e5] md:h-[125px] md:w-[125px]">
              <Image
                src={
                  ProfilePic.length > 0
                    ? `${ProfilePic[0]?.preview ?? userProfilePicture}`
                    : `${
                        userProfilePicture.length > 0
                          ? userProfilePicture
                          : `${session?.user?.image ?? "/default.jpeg"}`
                      }`
                }
                fill
                alt="Uploaded Image"
                className="rounded-full object-cover bg-white-50 object-center"
              />

              <div
                onClick={() => removeProfile()}
                {...getRootProps()}
                className="absolute top-1 right-1 flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-full border-[1px] border-[#f5f5f5] bg-white-50"
              >
                <input {...getInputProps()} />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={2}
                  stroke="#404040"
                  className="h-6 w-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                  />
                </svg>
              </div>
            </div>
          ) : (
            <div
              className="mx-auto flex h-[100px] w-[100px] flex-col items-center justify-center gap-6 rounded-full border-[1px] border-[#7F82BA] px-8 text-center md:h-[150px] md:w-[150px]"
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              <div className="flex h-[50px] w-[50px] items-center justify-center rounded-full bg-white-50">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={2}
                  stroke="#000"
                  className="h-6 w-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 4.5v15m7.5-7.5h-15"
                  />
                </svg>
              </div>
              <p className="text-[0.875rem] text-gray-600">
                Upload your profile picture
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
