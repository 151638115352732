import { formatDuration, processDate } from "components/common/processDate";
import Image from "next/image";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedVideo } from "redux/show/showSlice";
import { useGetLibraryQuery } from "services/baseApiSlice";

export default function SelectVideo({
  close,
  showCreateModal,
  edit,
}: {
  close: () => void;
  showCreateModal: () => void;
  edit?: string;
}) {
  const userProfile = useSelector((state: any) => state.auth.userProfile);
  const storeId = userProfile?.stores[0]?.storeId;

  const {
    currentData: data,
    refetch,
    isFetching,
  } = useGetLibraryQuery(storeId, {
    skip: !storeId,
  });

  const getActiveTabData = () => {
    switch (activeTab) {
      case "Uploads":
        return data?.library?.uploadedVideos;
      case "Recordings":
        return data?.library?.recordedVideos;
      case "TikTok":
        return [];
      case "Instagram":
        return [];
      case "YouTube":
        return [];

      default:
        return [];
    }
  };

  const [videos, setVideos] = React.useState<any[]>([]);
  const [selected, setSelected] = React.useState<any>(null);

  useEffect(() => {
    if (data && videos?.length === 0) {
      setVideos([
        ...data?.library?.uploadedVideos,
        ...data?.library?.recordedVideos,
      ]);
    }
  }, [data]);

  const dispatch = useDispatch();

  const tabs: {
    name:
      | "Uploads"
      | "TikTok"
      | "Instagram"
      | "YouTube"
      | "Recordings"
      | "activeTab";
    icon: React.ReactNode;
    count: number;
  }[] = [
    {
      name: "Uploads",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          className="lucide lucide-upload-cloud mr-2"
        >
          <path d="M4 14.899A7 7 0 1 1 15.71 8h1.79a4.5 4.5 0 0 1 2.5 8.242" />
          <path d="M12 12v9" />
          <path d="m16 16-4-4-4 4" />
        </svg>
      ),
      count: 12,
    },
    {
      name: "Recordings",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          className="lucide lucide-video mr-2"
        >
          <path d="m22 8-6 4 6 4V8Z" />
          <rect width="14" height="12" x="2" y="6" rx="2" ry="2" />
        </svg>
      ),
      count: 12,
    },
    {
      name: "TikTok",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          className="lucide lucide-music-2 mr-2"
        >
          <circle cx="8" cy="18" r="4" />
          <path d="M12 18V2l7 4" />
        </svg>
      ),
      count: 5,
    },
    {
      name: "Instagram",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          className="lucide lucide-instagram mr-2"
        >
          <rect width="20" height="20" x="2" y="2" rx="5" ry="5" />
          <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z" />
          <line x1="17.5" x2="17.51" y1="6.5" y2="6.5" />
        </svg>
      ),
      count: 8,
    },
    {
      name: "YouTube",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          className="lucide lucide-youtube mr-2"
        >
          <path d="M2.5 17a24.12 24.12 0 0 1 0-10 2 2 0 0 1 1.4-1.4 49.56 49.56 0 0 1 16.2 0A2 2 0 0 1 21.5 7a24.12 24.12 0 0 1 0 10 2 2 0 0 1-1.4 1.4 49.55 49.55 0 0 1-16.2 0A2 2 0 0 1 2.5 17" />
          <path d="m10 15 5-3-5-3z" />
        </svg>
      ),
      count: 2,
    },
  ];

  const [activeTab, setActiveTab] = useState<
    "Uploads" | "TikTok" | "Instagram" | "YouTube" | "Recordings" | "activeTab"
  >("Uploads");

  const getCountOfVideosFromType = (type: string) => {
    switch (type) {
      case "Uploads":
        return data?.library?.uploadedVideos?.length;
      case "Recordings":
        return data?.library?.recordedVideos?.length;
      case "TikTok":
        return 0;
      case "Instagram":
        return 0;
      case "YouTube":
        return 0;

      default:
        return 0;
    }
  };

  return (
    <div
      onClick={(e) =>
        e.currentTarget === e.target
          ? [dispatch(setSelectedVideo(undefined)), close()]
          : null
      }
      className="fixed inset-0 z-[200] flex h-screen w-screen items-center justify-center bg-black/50 backdrop:blur-md"
    >
      <div className="flex h-full md:max-h-[800px] w-full md:max-w-[1400px] relative flex-col justify-between rounded-xl bg-white-50 py-4 pt-0 pb-0 shadow-sm lg:h-[85vh] lg:w-[85vw]">
        <div className="flex items-center border-b-[1px] border-b-indigo-100 justify-between h-[50px] px-8">
          <h2 className="text-lg font-semibold text-gray-800">Video Files</h2>

          <div
            className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-neutral-300 text-gray-800"
            onClick={() => close()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="h-6 w-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </div>
        </div>

        <div className="mx-0 flex h-auto absolute left-0 md:bottom-[60px] bottom-[unset] top-[50px] pr-4 md:flex-col flex-col mb-auto md:border-r-[1px] md:border-r-indigo-100 mr-auto md:w-[225px] w-full items-start flex-wrap pt-0 justify-start overflow-y-auto no-scrollbar pl-8 gap-4 bg-white-50 p-0">
          <h2 className="text-gray-200 font-medium text-base mt-4">Sources</h2>
          <ul className="no-scrollbar md:flex-col flex-row flex w-full items-start md:gap-0 gap-4 overflow-x-auto no-scrollbar text-sm font-semibold text-gray-400">
            {tabs.map((tab, i) => (
              <li
                className={`w-full border-[1px] relative pl-0 py-1 items-center rounded-md ${
                  tab.name === "activeTab"
                    ? "border-tdark bg-tdark/10"
                    : "border-transparent"
                }`}
                onClick={() => {
                  if (
                    tab.name === "TikTok" ||
                    tab.name === "Instagram" ||
                    tab.name === "YouTube"
                  ) {
                    return;
                  }
                  setActiveTab(tab.name);
                }}
                key={i}
              >
                <button
                  className={`group inline-flex w-max items-center h-full justify-start rounded-t-lg p-4 pl-0 py-0 ${
                    tab.name === activeTab
                      ? "text-tdark"
                      : "text-gray-200 hover:text-gray-200"
                  }`}
                >
                  {tab.icon}
                  {tab.name}

                  <span className="md:absolute md:ml-0 ml-2 h-6 w-6 rounded-full bg-neutral-200 flex justify-center items-center right-2">
                    {getCountOfVideosFromType(tab.name)}
                  </span>
                </button>
              </li>
            ))}
          </ul>
        </div>
        <div className="relative mx-0 flex md:h-[calc(100%_-_110px)] h-[calc(100%_-_300px)] mt-auto md:ml-auto md:w-[calc(100%_-_225px)] w-full pb-16 items-start flex-wrap pt-6 justify-start overflow-y-auto no-scrollbar pl-4 gap-4 bg-neutral-200 p-0">
          {/* <div className="hr-color absolute inset-x-0 top-0 mb-0 h-[1px] w-full" /> */}
          <div className="w-full h-[60px] bg-white-50 hidden items-center justify-start gap-2 absolute top-0 inset-x-0 px-4 py-4">
            <form
              className="w-max relative h-max"
              onSubmit={(e) => e.preventDefault()}
            >
              <input
                type="text"
                placeholder="Search..."
                name="search"
                className="common-input w-[300px] !bg-transparent !border-gray-200 !pl-10"
              />
              <svg
                role="button"
                onClick={(e) => e.preventDefault()}
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="lucide lucide-search absolute left-2 top-1/2 -translate-y-1/2"
              >
                <circle cx="11" cy="11" r="8" />
                <path d="m21 21-4.3-4.3" />
              </svg>
            </form>

            <button className="bg-tdark text-white-50 font-semibold text-sm px-4 py-2 rounded-lg absolute right-8">
              New Video
            </button>
          </div>
          {videos &&
            getActiveTabData()?.map((video: any) => (
              <VideoCard
                key={video?.videoURL}
                video={video}
                type={""}
                refresh={() => refetch()}
                showPreview={() => {
                  setSelected(video);
                }}
                fromSelect
                isSelected={selected?.videoURL === video?.videoURL}
              />
            ))}
          {getActiveTabData()?.length === 0 && !isFetching && (
            <div className="flex flex-col items-center justify-center w-full h-full">
              <h2 className="text-gray-600 font-semibold text-base">
                No videos found
              </h2>
            </div>
          )}
          {isFetching &&
            [...Array(4)].map((_, index) => (
              <div
                key={index}
                className={`shrink-0 relative flex cursor-pointer  flex-col justify-between rounded-lg border-gray-800 bg-neutral-100 shadow-lg p-2 lg:w-[calc((100%/2)_-_15px)] xl:w-[calc((100%/2)_-_15px)] 2xl:w-[calc((100%/5)_-_15px)] w-full h-[200px] max-h-[300px]`}
              >
                <div
                  className={`z-40 relative rounded-t-md bg-neutral-300 animate-pulse lg:!object-contain object-cover h-[60%]`}
                ></div>

                <div
                  id="disable-one"
                  className="desc h-[60px] w-full mx-auto mb-2 bg-neutral-300 animate-pulse text-gray-800"
                ></div>
              </div>
            ))}
        </div>

        <div className="flex items-center relative h-[60px] mt-auto border-t-[1px] border-t-indigo-100 justify-between px-8">
          {/* <div className="absolute h-full w-[0px] border-r-[1px] border-r-indigo-100 left-[299px]"></div> */}
          <button
            onClick={() => close()}
            className="h-max w-max rounded-[5px] bg-[#7F82BA] py-2 px-8 text-[14px] font-medium text-white-50"
          >
            Cancel
          </button>

          <button
            onClick={() => {
              dispatch(setSelectedVideo(selected));
              showCreateModal();
            }}
            disabled={!selected}
            className="w-max h-max rounded-[5px] bg-[#25CC89] py-2 text-[14px] font-medium text-white-50 disabled:opacity-50 px-8"
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
}

export function VideoCard({
  video,
  type,
  refresh,
  showPreview,
  fromSelect,
  isSelected,
}: {
  video?: any;
  hideTitle?: boolean;
  type: string;
  refresh: () => void;
  showPreview: () => void;
  fromSelect?: boolean;
  isSelected?: boolean;
}) {
  const videoRef = useRef<HTMLVideoElement>(null);

  const [duration, setDuration] = useState(0);

  const secondsToMins = (seconds: number): string => {
    if (!seconds) return "0:00";
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;

    return `${mins}:${secs < 10 ? `0${secs.toFixed(0)}` : secs.toFixed(0)}`;
  };

  const userProfile = useSelector((state: any) => state.auth.userProfile);

  useEffect(() => {
    if (videoRef?.current) {
      videoRef.current.addEventListener("loadedmetadata", () => {
        setDuration(videoRef?.current?.duration ?? 0);
      });
    }
  }, []);

  const [name, setName] = useState("");

  useEffect(() => {
    if (video?.name) {
      setName(video?.name);
    }
  }, [video?.name]);

  return (
    <div
      className={`shrink-0 relative flex cursor-pointer  flex-col justify-between rounded-lg border-gray-800 bg-white-50 min-w-[270px] lg:w-[calc((100%/2)_-_15px)] xl:w-[calc((100%/3)_-_15px)] 2xl:w-[calc((100%/4)_-_15px)] w-full h-[280px] max-h-[300px] ${
        fromSelect ? (isSelected ? "selectedVideo" : "vidCard") : ""
      }`}
    >
      <video
        onContextMenu={(e) => e.preventDefault()}
        onClick={() => {
          showPreview();
        }}
        muted
        autoPlay
        ref={videoRef}
        className={`z-40 relative rounded-t-md bg-black lg:!object-contain object-cover ${
          fromSelect ? "!h-[70%] w-auto lg:!object-cover !object-cover" : ""
        }`}
      >
        Your browser does not support the video tag.
        <source src={video?.videoURL} type="video/mp4" />
      </video>

      <span
        className={`absolute z-50 right-2 rounded-lg bg-black/70 px-2 font-medium text-sm text-white-50 ${
          fromSelect ? "top-[150px]" : "top-[55%]"
        }`}
      >
        {secondsToMins(duration ?? 0)}
      </span>

      <div
        id="disable-one"
        className="desc absolute bottom-2 w-full px-2 text-gray-800"
      >
        <div
          id="disable-two"
          className="flex w-full flex-row-reverse items-center justify-end gap-2 mb-3"
        >
          <h3
            onClick={() => {
              showPreview();
            }}
            className="title w-full truncate block py-0 my-0 cursor-pointer font-bold"
          >
            {video?.name?.slice(0, -4) ?? "Untitled"}
          </h3>
        </div>

        <div
          id="disable-three"
          className="description mb-2 flex items-center justify-between border-gray-400 py-0 pb-0 text-sm text-gray-200"
        >
          <p>
            <span className="font-semibold mr-2">
              {userProfile?.stores[0]?.businessUsername}
            </span>
            Uploaded{" "}
            {formatDuration(video?.uploadedDate ?? video?.recordedDate)} ago
          </p>
          {!showPreview && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="h-6 w-6 cursor-pointer text-rose-600"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
              />
            </svg>
          )}
        </div>
      </div>
    </div>
  );
}
