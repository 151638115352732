// @ts-ignore
import { VerifyBanner } from "components/auth/useAuth";
// @ts-ignore
import notification from "components/common/notification";
import Image from "next/image";
import { useRouter } from "next/router";
import { useSession } from "next-auth/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUserProfile } from "redux/auth/authSlice";
import { useVerifyEmailQuery } from "services/baseApiSlice";

export default function TopNav({
  toggleSidebar,
  activeIndex,
  data,
  searchValuePass,
  showDropDown,
  showAuthModal,
  hideSearch,
}: {
  toggleSidebar: () => void;
  activeIndex: number;
  data: any;
  setSearchResults: (data: any[]) => void;
  searchValuePass: (data: string) => void;
  showDropDown: () => void;
  showAuthModal: () => void;
  hideSearch?: boolean;
  fromNoDevices?: boolean;
}) {
  const router = useRouter();

  const { shopId, streamID, type } = router.query;

  const userProfile = useSelector((state: any) => state.auth.userProfile);
  const { data: session } = useSession();

  const [searchData, setSearchData] = useState([]);

  useEffect(() => {
    if (data) {
      setSearchData(data);
    }
  }, [data]);

  useEffect(() => {
    if (activeIndex) {
      setSearchData([]);
    }
  }, [activeIndex]);

  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    setSearchValue("");
  }, [type]);

  const { token, user, tzwOrigin } = router.query;

  const dispatch = useDispatch();

  const { data: verified } = useVerifyEmailQuery(
    { token, user, tzwOrigin },
    { skip: !token || !user || userProfile?.isActive || !router.isReady }
  );

  useEffect(() => {
    if (verified && !userProfile?.isActive) {
      notification({
        status: "success",
        message: verified.message,
      });
      dispatch(setUserProfile(verified.user));
      router.replace(`/${userProfile?.stores[0]?.storeId}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verified, tzwOrigin, userProfile]);

  return (
    <header
      className={`fixed z-[150] h-[58px] w-full items-center bg-[#fff] py-2 pt-4 shadow ${
        streamID ? "hidden md:flex" : "flex"
      } ${
        !userProfile?.isActive &&
        userProfile?.authType === "email" &&
        userProfile
          ? "top-[50px]"
          : "top-0"
      }`}
    >
      {!userProfile?.isActive &&
        userProfile?.authType === "email" &&
        userProfile && <VerifyBanner />}
      <div className="relative ml-[0.1rem] mb-2 flex items-center gap-4 pl-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#243669"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          className={`hidden min-w-[32px] transition-all duration-300 ease-in md:block `}
          onClick={() => {
            toggleSidebar();
          }}
        >
          <line x1="4" y1="12" x2="20" y2="12"></line>
          <line x1="4" y1="6" x2="20" y2="6"></line>
          <line x1="4" y1="18" x2="20" y2="18"></line>
        </svg>
        <div className="absolute left-0 ml-4 h-[40px] w-[40px] cursor-pointer md:left-[50px]">
          <Image
            onClick={() => {
              if (streamID) {
                window.location.href = `/${shopId}`;
              } else {
                router.push(`/${shopId}`);
              }
            }}
            src="/logo.png"
            fill
            alt="TranzactWith"
            className="object-contain"
          />
        </div>
      </div>

      {!hideSearch && (
        <div className="fixed left-[250px] top-0 z-[60] hidden h-[58px] w-[1px] bg-[#e5e5e5] md:block" />
      )}

      {!hideSearch && data && data?.length > 0 && (
        <form className="relative z-50 ml-[250px] hidden w-max items-center gap-4 transition-all duration-300 ease-in md:flex">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="#243669"
            className="h-5 w-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 18.75a6 6 0 006-6v-1.5m-6 7.5a6 6 0 01-6-6v-1.5m6 7.5v3.75m-3.75 0h7.5M12 15.75a3 3 0 01-3-3V4.5a3 3 0 116 0v8.25a3 3 0 01-3 3z"
            />
          </svg>

          <input
            type="text"
            id="search"
            name="search"
            placeholder={
              activeIndex === 0
                ? "Search store ..."
                : activeIndex === 1 || activeIndex === 8
                ? "Search shows ..."
                : "Search products ..."
            }
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
              searchValuePass(e.target.value);
            }}
            className="topnav-search-input w-[400px] text-white-50"
          />
        </form>
      )}

      <div className="absolute right-4 mb-2 flex w-max items-center gap-6">
        <div className="relative flex gap-2 items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            className="lucide lucide-life-buoy cursor-pointer"
            onClick={() => {
              // open email on new tab
              window.open("mailto:hello@tranzactwith.com");
            }}
          >
            <circle cx="12" cy="12" r="10" />
            <path d="m4.93 4.93 4.24 4.24" />
            <path d="m14.83 9.17 4.24-4.24" />
            <path d="m14.83 14.83 4.24 4.24" />
            <path d="m9.17 14.83-4.24 4.24" />
            <circle cx="12" cy="12" r="4" />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="h-[30px] w-[30px] hidden"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0"
            />
          </svg>
        </div>
        {userProfile ? (
          <div
            onClick={() => {
              showDropDown();
            }}
            className="flex w-max cursor-pointer flex-row-reverse items-center gap-4"
          >
            <div className="flex w-full flex-col gap-1 text-xs font-medium">
              <span className="w-full text-blue break-all text-[11px] font-medium">
                Administrator
              </span>
              <p className="flex cursor-pointer items-center text-[14px] font-bold text-gray-300">
                {userProfile?.firstName + " " + (userProfile?.lastName ?? "")}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="h-5 w-5"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                    clipRule="evenodd"
                  />
                </svg>
              </p>
            </div>
            <div className="relative h-[32px] w-[32px] shrink-0 rounded-full">
              <Image
                src={
                  userProfile?.profilePictureURL ??
                  `${session?.user?.image ?? "/default.jpeg"}`
                }
                fill
                alt="Vendor Pic"
                className="rounded-full object-cover bg-white-50 object-center"
              />
            </div>
          </div>
        ) : (
          <button
            onClick={() => {
              showAuthModal();
            }}
            className="rounded-3xl bg-black px-8 py-2 font-semibold text-white-50"
          >
            Sign In
          </button>
        )}
      </div>
    </header>
  );
}
