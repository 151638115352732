import { processDate } from "components/common/processDate";
// @ts-ignore
import { formatDuration } from "components/common/processDate";
// @ts-ignore
import { Spinner } from "components/common/spinner";
import { AnimatePresence, motion } from "framer-motion";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { useSession } from "next-auth/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setBusinessProfile } from "redux/auth/authSlice";
import { setSavedShow } from "redux/show/showSlice";
import {
  useCancelShowMutation,
  useGetAllProductsAllQuery,
  useGetCountOfShowMessagesQuery,
  useGetShopInfoQuery,
  useGetShowInfoUsingStreamIDMutation,
  useUpdateShowInfoMutation,
} from "services/baseApiSlice";
import io from "socket.io-client"; // Add this

import { dateInCurrentTimeZone, getShowStatus, kFormater } from "./utils";
import notification from "../../common/notification";
import VideoPlayer from "../../videojs/main-hook";

let socket;

export const initiateSocketConnection = () => {
  socket = io(
    process.env.STAGE === "production"
      ? "https://tzw-backend.herokuapp.com"
      : `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:7000"
            : "https://tzw-backend-dev.herokuapp.com"
        }`
  );
};
export const disconnectSocket = () => {
  if (socket) socket.disconnect();
};

let mediaRecorder;
const recordedChunks = [];
let client;

const socketUrl = "wss://stream.sellhustle.com:3000";

function handleError(error) {
  console.log(
    "navigator.MediaDevices.getUserMedia error: ",
    error.message,
    error.name
  );
  if (error.name === "NotAllowedError") {
    notification({
      status: "error",
      message: "Please allow access to your camera and microphone",
    });
    return;
  }
  if (error.name === "NotFoundError") {
    notification({
      status: "error",
      message: "No camera or microphone found",
    });
    return;
  }

  if (error.name === "DevicesNotFoundError") {
    return;
  }

  notification({
    status: "error",
    message: error?.message ?? "An error occured while getting devices",
  });
}

export default function LiveStream() {
  // streaming functions
  const handleDataAvailable = async (e) => {
    if (e.data && e.data.size > 0) {
      recordedChunks.push(e.data);
      client.send(e.data);
    }
  };

  const startRec = async (streamID) => {
    const stkey = streamID;
    client = new WebSocket(`${socketUrl}/rtmp?key=${stkey}`);
    client.onopen = () => {
      streamHandler();
    };
    client.onerror = () => {
      notification({
        status: "error",
        message: `Error connecting to streaming server. Please try again later`,
      });
    };
  };

  const streamHandler = async () => {
    const videoElement2 = document.getElementById("video_cam");
    if (!videoElement2) {
      notification({
        status: "error",
        message: "Stream not ready.",
      });
      return;
    }

    const stream_new = await navigator.mediaDevices.getUserMedia({
      video: true,
      audio: true,
    });

    window.localStream = stream_new;

    // Preview the source in a video element
    videoElement2.srcObject = stream_new;

    videoElement2.play();

    mediaRecorder = null;

    mediaRecorder = new MediaRecorder(stream_new);
    if (!mediaRecorder) {
      notification({
        status: "error",
        message: "Stream not ready.",
      });

      return;
    }
    mediaRecorder.ondataavailable = handleDataAvailable;
    mediaRecorder.start(1500);
    mediaRecorder.onerror = (e) => {
      notification({
        status: "error",
        message: `Error: ${JSON.stringify(e)}`,
      });
    };

    if (mediaRecorder.state === "recording") {
      notification({
        status: "success",
        message: "Streaming started",
      });
      setShowLive(true);
      updateShowDetails("started");
      const startStream = document.querySelector(".start_stream");
      const stopStream = document.querySelector(".stop_stream");
      if (startStream) {
        startStream.style.display = "none";
      }
      if (stopStream) {
        stopStream.style.display = "flex";
      }
    }
  };
  // end streaming functions
  const { data: session } = useSession();
  useEffect(() => {
    initiateSocketConnection();
    return () => disconnectSocket();
  }, []);

  const router = useRouter();
  const { streamID, playType, shopId: storeID } = router.query;

  const businessProfile = useSelector((state) => state.auth.businessProfile);

  const [joinedChat, setJoinedChat] = useState(false);
  const userProfile = useSelector((state) => state.auth.userProfile);

  const joinRoom = () => {
    if (joinedChat) return;
    setMessagesReceived([]);
    socket.emit("join_room", {
      username: `${userProfile?.firstName} ${userProfile?.lastName}`,
      room: streamID,
    });
    setJoinedChat(true);
  };

  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState("");
  const [activeTabMobile, setActiveTabMobile] = useState("");

  const savedShow = useSelector((state) => state.show.savedShow);

  const [getShow, { isLoading }] = useGetShowInfoUsingStreamIDMutation();

  const [messagesRecieved, setMessagesReceived] = useState([]);

  const [lastNotifIndex, setLastNotifIndex] = useState("");

  useEffect(() => {
    socket.on("show_messages", (showMessages) => {
      if (showMessages?.length > 0) {
        setMessagesReceived((state) => [...showMessages, ...state]);
        setLastNotifIndex(showMessages.length - 1);
      }
    });

    return () => socket.off("show_messages");
  }, []);

  // Runs whenever a socket event is recieved from the server

  const [messageNotifications, setMessageNotifications] = useState([]);

  useEffect(() => {
    socket.on("receive_message", (data) => {
      setMessagesReceived((state) => [
        ...state,
        {
          message: data.message,
          _id: data._id,
          username: data.username,
          createdAt: data.createdAt,
          profilePicture: data.profilePicture,
          isVendor: data.isVendor,
          replyingTo: {
            message: data.replyBody?.message,
            username: data.replyBody?.username,
          },
        },
      ]);
    });

    // Remove event listener on component unmount
    return () => socket.off("receive_message");
  }, []);

  const [message, setMessage] = useState("");
  const [scrollDown, setScrollDown] = useState(false);
  const [replyingTo, setReplyingTo] = useState(null);

  const sendMessage = () => {
    if (message !== "") {
      const __createdtime__ = Date.now();
      socket.emit("send_message", {
        username: `${userProfile?.firstName} ${userProfile?.lastName}`,
        room: streamID,
        profilePicture:
          businessProfile?.storeLogoURL?.length > 0
            ? businessProfile?.storeLogoURL
            : `https://ik.imagekit.io/vpmuiyu4v/defaul-store.webp?updatedAt=1693567474118`,
        user_id: userProfile?._id ?? "",
        replyingTo: replyingTo?._id,
        replyBody: replyingTo,
        createdAt: Date.now(),
        isVendor: true,
        message,
        __createdtime__,
      });
      setMessage("");
      setScrollDown(!scrollDown);
      setReplyingTo(null);
    }
  };

  const { data, isFetching } =
    // @ts-ignore
    useGetAllProductsAllQuery();

  // eslint-disable-next-line unused-imports/no-unused-vars
  const [liveProducts, setLiveProducts] = useState();
  const [allProducts, setAllProducts] = useState([]);

  useEffect(() => {
    if (data) {
      setAllProducts(data.products);
    }
  }, [data]);

  useEffect(() => {
    if (savedShow?.products?.length > 0) {
      const productIds = savedShow?.products;
      // get products from state allProducts and set to liveProducts
      const liveProducts = allProducts.filter((product) => {
        return productIds.includes(product._id);
      });
      setLiveProducts(liveProducts);
    }
  }, [savedShow, streamID, allProducts]);

  const fetchShow = async () => {
    const showId = streamID;
    await getShow({ showId })
      .unwrap()
      .then((payload) => {
        dispatch(setSavedShow(payload));
        // const path = router.asPath.split("?")[0];
        // router.replace(path, path, {
        //   shallow: true,
        // });
      })
      .catch((error) => {
        notification({
          status: "error",
          message: error?.message ?? error?.data?.message,
        });
      });
  };

  useEffect(() => {
    if (streamID) {
      fetchShow();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [streamID]);

  useEffect(() => {
    if (savedShow?.store?.storeId && storeID) {
      if (savedShow?.store?.storeId !== storeID) {
        router.replace(`/${storeID}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeID, savedShow?.store?.storeId]);

  // controls
  const [micOpen, setMicOpen] = useState(true);
  const [cameraOpen, setCameraOpen] = useState(true);
  const [streamConstraints, setStreamConstraints] = useState({});
  const [videoElement, setVideoElement] = useState(null);

  const [audioInputSelect, setAudioInputSelect] = useState(null);
  const [audioOutputSelect, setAudioOutputSelect] = useState(null);
  const [videoSelect, setVideoSelect] = useState(null);
  const [selectors, setSelectors] = useState(null);

  function stopVideoOnly(stream, type) {
    if (type === "close") {
      window.stream.getTracks().forEach((track) => {
        track.stop();
      });
    } else if (type === "open") {
      const video = document.getElementById("video_cam");

      if (!video) return;

      navigator.mediaDevices
        .getUserMedia(streamConstraints)
        .then((stream) => {
          setStream(stream);
          video.srcObject = stream;
          video.play();
        })
        .catch(handleError);
    }
  }

  function stopAudioOnly(stream, type) {
    if (type === "open") {
      window.stream.getTracks().forEach(function (track) {
        if (track.readyState == "live" && track.kind === "audio") {
          track.stop();
        }
      });
    } else if (type === "close") {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then(function (stream) {
          stream.getTracks().forEach(function (track) {
            if (track.readyState == "live" && track.kind === "audio") {
              track.enabled = true;
            }
          });
        });
    }
  }

  useEffect(() => {
    const chatScrollElement = document.getElementById("chatScroll");
    if (chatScrollElement) {
      chatScrollElement.scrollTop = chatScrollElement.scrollHeight + 100;
    }
    setLastNotifIndex(messagesRecieved?.length - 1);
  }, [messagesRecieved, scrollDown]);

  useEffect(() => {
    if (messagesRecieved?.length > 0) {
      if (
        lastNotifIndex === 0 ||
        lastNotifIndex === messagesRecieved?.length - 1
      )
        return;
      // get all messages after index lastNotifIndex: messagesRecieved.slice(lastNotifIndex, messagesRecieved?.length)
      const newMessages = messagesRecieved.slice(
        lastNotifIndex + 1,
        messagesRecieved?.length
      );

      setMessageNotifications(newMessages);
    }
  }, [messagesRecieved, lastNotifIndex]);

  useEffect(() => {
    if (messageNotifications?.length > 0) {
      const timerToClearNotif = setTimeout(() => {
        setMessageNotifications([]);
      }, 10000);

      return () => {
        clearTimeout(timerToClearNotif);
      };
    }
  }, [messageNotifications]);

  const openShareModal = async () => {
    const isStaging = window.location.hostname.includes("staging");

    let link = "";

    if (isStaging) {
      link = `https://staging-tzw.vercel.app/watch/${streamID}`;
    } else {
      link = `https://tranzactwith.com/watch/${streamID}`;
    }
    try {
      // open navigator share
      const shareData = {
        title: "Share this show",
        url: link,
      };
      await navigator.share(shareData);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  };

  const [currentDate, setCurrentDate] = useState(new Date());

  useEffect(() => {
    const dateNow = new Date();
    setCurrentDate(dateNow);

    // update time every minute
    const interval = setInterval(() => {
      const dateNow = new Date();
      setCurrentDate(dateNow);
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      // when show is Live, refresh show every 5 seconds
      if (playType === "stream") {
        fetchShow();
      } else {
        clearInterval(interval);
      }
    }, 7000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playType]);

  const [{ isLoading: updating }] = useCancelShowMutation();

  const [hasWarned, setHasWarned] = useState(false);

  useEffect(() => {
    // call cancelShow if savedShow.start_date + 15 minutes have passed
    if (
      savedShow &&
      savedShow?.start_date &&
      savedShow?.isActive &&
      !savedShow?.endedStream &&
      playType === "stream"
    ) {
      const startDate = dateInCurrentTimeZone(new Date(savedShow.start_date));
      const endDate = new Date(startDate.getTime() + 30 * 60000);

      // show toast if show is about to end: 3 minutes to go
      const minsRemaining = (endDate - currentDate) / 60000;
      if (currentDate > new Date(endDate.getTime() - 3 * 60000) && !hasWarned) {
        notification({
          status: "info",
          message: `Your show will end in ${
            minsRemaining.toFixed(0) > 0
              ? `${minsRemaining.toFixed(0)} minutes`
              : "a few seconds"
          }`,
        });
        setHasWarned(true);
        return;
      }

      if (currentDate > endDate) {
        updateShowDetails("done", true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDate, savedShow, playType]);

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  function stopRec() {
    mediaRecorder.stop();
    const startStream = document.querySelector(".start_stream");
    const stopStream = document.querySelector(".stop_stream");
    if (startStream) {
      startStream.style.display = "flex";
    }
    if (stopStream) {
      stopStream.style.display = "none";
    }
    mediaRecorder = undefined;
    notification({
      status: "info",
      message: "Stream stopped successfully",
    });
    setShowConfirmModal(false);
  }

  const [stream, setStream] = useState(null);

  const start = () => {
    const video = document.getElementById("video_cam");

    if (stream || !video) return;
    navigator.mediaDevices
      .getUserMedia({
        audio: true,
        video: true,
      })
      .then((stream) => {
        video.srcObject = stream;
        video.play();
        setStream(stream);
      })
      .catch(handleError);
  };

  const [isMobile, setIsMobile] = useState(null);

  useEffect(() => {
    if (isMobile && userProfile && streamID) {
      joinRoom();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile, streamID, isMobile]);

  const [hasStreamDevices, setHasStreamDevices] = useState(null);

  useEffect(() => {
    if (playType === "stream") {
      // check for support
      if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
        return;
      }

      navigator.mediaDevices
        .enumerateDevices()
        .then((deviceInfos) => {
          if (deviceInfos.find((device) => device.kind === "videoinput")) {
            console.log("has video input");
          } else {
            setHasStreamDevices(false);
          }
        })
        .catch(handleError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectors, playType, isMobile]);

  useEffect(() => {
    if (audioOutputSelect) {
      audioOutputSelect.disabled = !("sinkId" in HTMLMediaElement.prototype);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      savedShow &&
      playType === "stream" &&
      savedShow?.streamStatus !== "done"
    ) {
      if (
        getShowStatus(
          savedShow?.start_date,
          savedShow?.durationInSeconds,
          savedShow
        ) === "Live"
      ) {
        start();
      } else {
        window.location.href = `/${businessProfile?._id}/watch/${streamID}`;
        console.log("show not live");
        notification({
          status: "info",
          message: "Show not live",
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedShow, playType]);

  const [isLiveShow, setIsLiveShow] = useState(false);

  useEffect(() => {
    if (savedShow?.streamStatus === "done" && playType === "stream") {
      setIsLiveShow(false);
      window.location.href = `/${businessProfile?._id}/watch/${streamID}`;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playType, savedShow]);

  useEffect(() => {
    const videoE = document.getElementById("video_cam");
    const audioInputS = document.querySelector("select#audioSource");
    const audioOutputS = document.querySelector("select#audioOutput");
    const videoS = document.querySelector("select#videoSource");

    if (videoE) {
      videoE.setAttribute("autoplay", "");
      videoE.setAttribute("muted", "");
      videoE.setAttribute("playsinline", "");
      setVideoElement(videoE);
    }
    if (audioInputS) {
      setAudioInputSelect(audioInputS);
    }
    if (audioOutputS) {
      setAudioOutputSelect(audioOutputS);
    }
    if (videoS) {
      setVideoSelect(videoS);
    }

    if (videoE && audioInputS && audioOutputS && videoS) {
      setSelectors([audioInputS, audioOutputS, videoS]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile, router]);

  const [updateShowProducts] = useUpdateShowInfoMutation();

  const updateShowDetails = async (streamStatus, endingShow = false) => {
    const obj = {
      streamStatus: streamStatus ? streamStatus : undefined,
      endedStream: endingShow ? true : undefined,
    };
    const showId = savedShow._id;
    if (endingShow) {
      notification({
        status: "info",
        message: "Your show has ended",
      });
    }
    await updateShowProducts({ obj, showId })
      .unwrap()
      .then((payload) => {
        dispatch(setSavedShow(payload));
        fetchShow();
        if (endingShow) {
          router.replace(
            `/${businessProfile?._id}/analytics/${savedShow?.streamID}`
          );
        }
      })
      .catch(() => {
        notification({
          status: "error",
          message: "An error occured while updating show details",
        });
      });
  };

  useEffect(() => {
    setIsMobile(window.innerWidth < 768);
    if (window.innerWidth > 768) {
      setActiveTab("shop");
      setActiveTabMobile("shop");
    }
  }, []);

  useEffect(() => {
    const isMobile = window.innerWidth < 768;
    if (isMobile) return;
    // set innerHeight of window to id element window
    const windowEl = document.getElementById("window");
    if (windowEl) {
      windowEl.style.height = `${window.innerHeight}px`;
    }

    // update body to - body { position: fixed; height: 100% }
    document.body.style.position = "fixed";
    document.body.style.height = "100%";
    // width: 100vw;
    document.body.style.width = "100vw";
    return () => {
      document.body.style.position = "static";
      document.body.style.height = "auto";
      document.body.style.width = "auto";
    };
  }, []);

  const { data: messagesCountInit } = useGetCountOfShowMessagesQuery(
    streamID,
    {
      skip: !streamID,
    },
    {
      pollingInterval: 60000,
      // refetchs every 60 seconds
    }
  );

  const [messagesCount, setMessagesCount] = useState(0);

  useEffect(() => {
    if (messagesCountInit) {
      setMessagesCount(messagesCountInit);
    }
    if (messagesRecieved.length > 0) {
      setMessagesCount(messagesRecieved.length);
    }
  }, [messagesCountInit, messagesRecieved]);

  const shopId = userProfile?.stores && userProfile?.stores[0]?.storeId;

  const { data: storeData } = useGetShopInfoQuery(shopId, {
    skip: !shopId,
  });

  useEffect(() => {
    if (storeData) {
      dispatch(setBusinessProfile(storeData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeData]);

  useEffect(() => {
    if (
      savedShow?.streamStatus === "started" &&
      getShowStatus(
        savedShow?.start_date,
        savedShow?.durationInSeconds,
        savedShow
      ) === "Live"
    ) {
      setIsLiveShow(true);
    }
  }, [savedShow]);

  const [streamURL, setStreamURL] = useState("");

  useEffect(() => {
    if (
      streamID &&
      (savedShow?.streamStatus === "started" ||
        savedShow?.streamStatus === "done")
    ) {
      const streamURL = `${process.env.NEXT_PUBLIC_STREAMING_URL_PREFIX}${streamID}/index.m3u8`;
      setStreamURL(streamURL);
    }
    return () => {
      setStreamURL("");
    };
  }, [streamID, savedShow]);

  const videoJsOptions = {
    controls: true,
    preload: "auto",
    playsinilie: true,
    loop: true,
    autoplay: true,
    muted: true,
    src: streamURL,
    liveui: isLiveShow ? true : undefined,
    poster: savedShow?.imageURL,
    html5: {
      hlsjsConfig: {
        debug: false,
      },
    },
  };

  useEffect(() => {
    // listen for when user is leaving the page and call stop camera and mic
    const closeMicAndCamera = () => {
      stopAudioOnly(stream, "close");
      stopVideoOnly(stream, "close");
    };
    return () => {
      if (window.stream) {
        closeMicAndCamera();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const isMobile = window.innerWidth < 768;
    // add style overflow hidden to body
    if (isMobile) {
      document.body.style.overflow = "hidden";
    }
    joinRoom();
    // remove style overflow hidden to body on unmount
    return () => {
      if (isMobile) {
        document.body.style.overflow = "unset";
      }
    };
  }, []);

  const [showLive, setShowLive] = useState(false);

  const endShow = async () => {
    await updateShowDetails("done", true);
    stopRec();
    stopVideoOnly(stream, cameraOpen ? "close" : "open");
    setCameraOpen(!cameraOpen);
    setShowLive(false);
  };

  const focusOnInput = () => {
    const input = document.getElementById("message");
    if (input) {
      input.focus();
    }
  };

  useEffect(() => {
    const confirmExit = (e) => {
      // Custom confirmation message
      const confirmationMessage =
        "You are still streaming. Are you sure you want to leave?";

      if (
        getShowStatus(
          savedShow?.start_date,
          savedShow?.durationInSeconds,
          savedShow
        ) === "Live" &&
        showLive
      ) {
        e.preventDefault();
        e.returnValue = confirmationMessage;
      }
    };

    // Attach the event listener for beforeunload
    window.addEventListener("beforeunload", confirmExit);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("beforeunload", confirmExit);
    };
  }, [showLive, savedShow]);

  if (isMobile === null || !router.isReady)
    return (
      <div className="fixed inset-0 z-50 flex h-full w-full items-center justify-center">
        <Spinner />
      </div>
    );

  return (
    <div
      id="window"
      className="no-scrollbar h-[100vh] max-h-screen overflow-hidden bg-[#f5f5f5] md:pt-[58px]"
    >
      <div className="no-scrollbar flex h-full w-full flex-col gap-8 overflow-hidden text-white-50">
        <div className="no-scrollbar relative flex h-full w-full flex-col overflow-hidden md:flex-row">
          {/* {hasStreamDevices === false && <NoCamera />} */}
          {/* live stream */}

          {/* chat */}
          {!isMobile && (
            <div className="relative block h-full w-[25%] overflow-hidden bg-[#f5f5f5] md:block md:h-[100%]">
              <div className="absolute left-0 z-[50] flex w-full flex-col items-start gap-0 bg-[#f5f5f5] px-0 py-2  md:w-full">
                <div className="hidden pl-4 md:block">
                  <h2 className="text-lg font-semibold text-black">Chats</h2>
                  <p className="text-sm text-gray-400">Messages from viewers</p>
                </div>
                <div className="mt-2 h-[1px] min-h-[1px] w-full min-w-[100%] bg-gray-100"></div>
              </div>
              <div className="relative flex h-full w-full flex-col-reverse justify-start pb-0 md:flex-col md:pb-0">
                {/* user chats */}
                <div
                  id="chatScroll"
                  className="no-scrollbar relative flex h-full w-full flex-col gap-6 overflow-y-scroll rounded-md py-6 pt-20 pb-12"
                >
                  {messagesRecieved.length === 0 ? (
                    <div className="absolute top-[40%] left-1/2 flex w-4/5 -translate-x-1/2 flex-col items-center gap-4 rounded-lg p-4 text-lg font-bold text-black">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="#104553"
                        className="h-12 w-12"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 20.25c4.97 0 9-3.694 9-8.25s-4.03-8.25-9-8.25S3 7.444 3 12c0 2.104.859 4.023 2.273 5.48.432.447.74 1.04.586 1.641a4.483 4.483 0 01-.923 1.785A5.969 5.969 0 006 21c1.282 0 2.47-.402 3.445-1.087.81.22 1.668.337 2.555.337z"
                        />
                      </svg>
                      <span>No messages here yet</span>
                    </div>
                  ) : (
                    messagesRecieved?.map((chat) => (
                      <div
                        key={chat?._id}
                        id={chat?._id}
                        className="flex w-full items-center gap-2 pl-2"
                      >
                        <OneEntry
                          userName={chat.username}
                          profileImage={
                            chat?.profilePicture?.length > 0
                              ? chat?.profilePicture
                              : `/default.jpeg`
                          }
                          message={chat.message}
                          replyingTo={chat?.replyingTo}
                          time={chat.createdAt}
                          isVendor={chat?.isVendor}
                          replyMessage={() => {
                            setReplyingTo({
                              message: chat?.message,
                              username: chat?.username,
                              _id: chat?._id,
                            });
                            focusOnInput();
                          }}
                        />
                      </div>
                    ))
                  )}
                  <div id="bacon" className="" />
                </div>
                {/* chat entry for admin */}
                <form
                  className={`flex h-max w-full items-center gap-6 bg-transparent py-1 pt-0 md:static md:bottom-0 md:h-[50px] md:w-full md:px-2 md:pb-4 ${
                    replyingTo ? "mb-8" : "mb-0"
                  }`}
                >
                  {getShowStatus(
                    savedShow?.start_date,
                    savedShow?.durationInSeconds,
                    savedShow
                  ) === "Was Live" &&
                  Math.abs(new Date(savedShow?.start_date) - new Date()) >=
                    2 * 60 * 60 * 1000 ? (
                    <div className="relative -mt-7 flex h-[50px] w-full items-center gap-4 rounded-lg bg-[#F3F3F3] px-4 font-medium text-gray-300">
                      <p className="text-sm">
                        Live chat is disabled after a show has aired.
                      </p>
                      <div
                        onClick={() => {
                          setActiveTabMobile("");
                          setActiveTab("");
                        }}
                        className="absolute right-2 flex h-[35px] w-[35px] items-center justify-center rounded-full bg-black/50 md:hidden"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={2}
                          stroke="#fff"
                          className="h-4 w-4"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </div>
                    </div>
                  ) : (
                    <div
                      className={`relative flex w-full flex-col items-center gap-0 border-b-[2px] border-[#f5f5f5] px-2 pt-[4rem] transition-transform duration-300 ease-in md:w-full md:pt-2 ${
                        replyingTo
                          ? "bg-[#0076bd] px-1 pb-1 md:pt-1"
                          : "bg-[#f5f5f5]"
                      }`}
                    >
                      <div className="absolute inset-x-0 top-0 flex h-max w-full items-center justify-between border-b-[2px] border-[#f5f5f5] py-3 px-2 md:hidden">
                        <h2 className="text-2xl font-semibold">Comments</h2>
                        <div
                          onClick={() => {
                            setActiveTabMobile("");
                            setActiveTab("");
                          }}
                          className="flex h-[35px] w-[35px] items-center justify-center rounded-full bg-black/50"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={2}
                            stroke="#fff"
                            className="h-4 w-4"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                        </div>
                      </div>
                      {replyingTo && (
                        <div className="relative flex h-[50px] w-full bg-[#f5f5f5] p-2 text-gray-300">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            onClick={() => {
                              setReplyingTo(null);
                            }}
                            className="absolute right-1 top-1 h-6 w-6 text-gray-400"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>

                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 48 48"
                            id="reply"
                            className="text-[#0076bd]"
                            stroke="currentColor"
                          >
                            <path
                              fill="currentColor"
                              d="M20 18v-8L6 24l14 14v-8.2c10 0 17 3.2 22 10.2-2-10-8-20-22-22z"
                            ></path>
                          </svg>
                          <div className="flex flex-col">
                            <span className="flex items-center text-xs font-semibold">
                              {replyingTo?.username}
                            </span>
                            <p className="w-full !text-base font-normal md:w-[100%]">
                              {replyingTo?.message.length > 30 ? (
                                <span>{replyingTo?.message.slice(0, 30)}</span>
                              ) : (
                                <span>{replyingTo?.message}</span>
                              )}
                            </p>
                          </div>
                        </div>
                      )}
                      <div
                        className={`flex w-full items-center gap-2 py-1 px-2 ${
                          replyingTo
                            ? "bg-[#f5f5f5]"
                            : "border-round bg-transparent"
                        }`}
                      >
                        <div className="relative h-[40px] w-[40px] min-w-[40px] shadow-sm outline-white-50 outline-[2px] rounded-full">
                          <Image
                            src={`${
                              businessProfile?.storeLogoURL?.length > 0
                                ? businessProfile?.storeLogoURL
                                : `https://ik.imagekit.io/vpmuiyu4v/defaul-store.webp?updatedAt=1693567474118`
                            }`}
                            fill
                            alt="profile"
                            className="rounded-full object-cover object-center"
                          />
                        </div>
                        <input
                          type="text"
                          id="message"
                          name="message"
                          disabled={
                            getShowStatus(
                              savedShow?.start_date,
                              savedShow?.durationInSeconds,
                              savedShow
                            ) === "Was Live"
                          }
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                          placeholder={
                            replyingTo ? "Reply..." : "Type a message..."
                          }
                          className={`common-input h-[40px] w-[100%] rounded-md text-[#243669] ${
                            replyingTo
                              ? "common-input-live-reply"
                              : "common-input-live"
                          }`}
                        />
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            sendMessage();
                          }}
                          disabled={!message}
                          className="text-white absolute right-4 flex h-[35px] w-[35px] min-w-[35px] items-center justify-center rounded-full bg-[#104553] font-semibold text-white-50"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="h-6 w-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  )}
                </form>
              </div>
            </div>
          )}

          <div className="relative z-10 h-full w-full rounded-lg bg-tdark/80 md:h-[100%] md:w-[50%]">
            {/* background image */}
            {savedShow?.imageURL && (
              <div
                style={{
                  backgroundImage: `url(${
                    savedShow?.imageURL ??
                    "https://res.cloudinary.com/dpnbddror/image/upload/v1646494823/1646494823423_bx1rjr.webp"
                  })`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}
                className="absolute inset-0 z-10 h-full w-full bg-no-repeat"
              />
            )}
            {playType === "stream" && !stream && hasStreamDevices === null && (
              <div className="absolute inset-0 z-20 flex h-full w-full items-center justify-center">
                <Spinner />
              </div>
            )}
            {/* loading state */}
            {isLoading && (
              <div className="absolute inset-0 z-20 flex h-full w-full items-center justify-center">
                <Spinner />
              </div>
            )}
            {/* upcoming show state */}
            {!isLoading &&
              getShowStatus(
                savedShow?.start_date,
                savedShow?.durationInSeconds,
                savedShow
              ) === "Upcoming" && (
                <div
                  style={{
                    backgroundImage: `url(${
                      savedShow?.imageURL ??
                      "https://res.cloudinary.com/dpnbddror/image/upload/v1646494823/1646494823423_bx1rjr.webp"
                    })`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                  }}
                  className="absolute inset-0 z-30 flex h-full w-full items-center justify-center"
                >
                  <div className="max-w-[70%] bg-black/50 rounded-md px-4 text-center text-white-50">
                    <h1 className="text-[18px] font-bold md:text-[32px]">
                      Live show starts at {processDate(savedShow?.start_date)}{" "}
                    </h1>
                  </div>
                </div>
              )}

            {/* cancelled show state */}
            {!isLoading && !savedShow?.isActive && (
              <div className="absolute inset-0 z-30 flex h-full w-full items-center justify-center">
                <div className="relative w-[500px] text-white-50">
                  <h1 className="w-max text-center text-[18px] font-bold md:text-[32px]">
                    This show has been cancelled
                  </h1>
                  <Link
                    href="/"
                    className="absolute left-1/2 -translate-x-1/2 text-center text-lg font-semibold underline"
                  >
                    Go back
                  </Link>
                </div>
              </div>
            )}

            {/* waiting for host state */}
            {playType === "watch" &&
              !isLoading &&
              getShowStatus(
                savedShow?.start_date,
                savedShow?.durationInSeconds,
                savedShow
              ) === "Live" &&
              savedShow?.streamStatus !== "started" && (
                <div className="absolute inset-0 z-30 flex h-full w-full items-center justify-center">
                  <div className="relative text-white-50 md:w-[500px]">
                    <h1 className="w-max text-center text-[18px] font-bold md:text-[32px]">
                      Show has not started, waiting for host
                    </h1>
                    <Link
                      href={`/${shopId}`}
                      className="absolute left-1/2 -translate-x-1/2 text-center text-lg font-semibold underline"
                    >
                      Go back
                    </Link>
                  </div>
                </div>
              )}
            {/* blur */}
            <div className="layover absolute inset-0 z-10 h-full w-full"></div>

            {/* hidden helper elements for stream */}
            <div className="absolute top-1/2 left-1/2 z-50 hidden -translate-y-1/2 -translate-x-1/2 rounded-lg bg-[#eaebef] p-4 px-8 text-[#243669]">
              <div className="select">
                <label htmlFor="audioSource">Audio input source: </label>
                <select id="audioSource"></select>
              </div>

              <div className="select">
                <label htmlFor="audioOutput">Audio output destination: </label>
                <select id="audioOutput"></select>
              </div>

              <div className="select">
                <label htmlFor="videoSource">Video source: </label>
                <select id="videoSource"></select>
              </div>
            </div>

            {/* for LIVE stream */}
            {savedShow &&
              getShowStatus(
                savedShow?.start_date,
                savedShow?.durationInSeconds,
                savedShow
              ) === "Live" &&
              !savedShow?.mediaURL && (
                <video
                  onContextMenu={(e) => e.preventDefault()}
                  controls={false}
                  muted
                  id="video_cam"
                  poster={savedShow?.imageURL}
                  className="relative z-20 h-full w-full bg-black !object-contain object-center"
                ></video>
              )}

            {/* for PLAYBACK  */}
            {(savedShow?.streamStatus === "started" ||
              savedShow?.streamStatus === "done") &&
              streamURL?.length > 0 &&
              savedShow?.imageURL?.length > 0 &&
              getShowStatus(
                savedShow?.start_date,
                savedShow?.durationInSeconds,
                savedShow
              ) === "Was Live" &&
              !savedShow?.mediaURL && <VideoPlayer {...videoJsOptions} />}

            {/* for videos from library */}
            {savedShow && savedShow?.mediaURL && (
              <video
                onContextMenu={(e) => e.preventDefault()}
                controls={false}
                autoPlay
                muted
                poster={savedShow?.imageURL}
                className="relative z-20 h-full w-full bg-black !object-contain object-center"
              >
                <source src={savedShow?.mediaURL} type="video/mp4" />
              </video>
            )}

            {/* time section */}
            {((playType === "stream" && showLive && !isLoading) ||
              (playType === "watch" &&
                !isLoading &&
                savedShow &&
                getShowStatus(
                  savedShow?.start_date,
                  savedShow?.durationInSeconds ?? 5,
                  savedShow
                ) !== "Upcoming")) && (
              <div className="absolute top-4 right-4 z-30 flex flex-col gap-4 text-white-50 md:right-12">
                <div className="flex items-center">
                  <div className="flex items-center  justify-center gap-2 rounded-3xl bg-white-50/50 py-2 px-2 md:hidden">
                    <div className="flex items-center gap-2">
                      <Image
                        src="/images/svgs/views.svg"
                        width={20}
                        height={20}
                        alt="Views"
                      />{" "}
                      <span className="font-medium">
                        {savedShow?.viewsCount ?? "0"}
                      </span>
                    </div>
                    {showLive}
                    <div className="flex items-center gap-2">
                      <Image
                        src="/images/svgs/comments.svg"
                        width={20}
                        height={20}
                        alt="Comments"
                      />{" "}
                      <span className="font-medium">
                        {kFormater(messagesCount ?? 0)}
                      </span>
                    </div>
                    <span
                      className={`ml-2 h-4/5 w-max rounded-3xl px-3 py-1 font-bold ${
                        getShowStatus(
                          savedShow?.start_date,
                          savedShow?.durationInSeconds ?? 5,
                          savedShow
                        ) === "Live"
                          ? "bg-red"
                          : "bg-gray-500"
                      }`}
                    >
                      {getShowStatus(
                        savedShow?.start_date,
                        savedShow?.durationInSeconds ?? 5,
                        savedShow
                      )}
                    </span>
                  </div>
                </div>
              </div>
            )}

            {/* vendor card */}
            {!isLoading && !storeData && (
              <div className="absolute top-0 left-2 z-30 hidden rounded-md p-4 text-white-50 transition-all duration-300 ease-in md:flex">
                <div className="flex items-center gap-4">
                  <Image
                    src={
                      savedShow?.profilePictureURL ??
                      `${session?.user?.image ?? "/default.jpeg"}`
                    }
                    alt="Vendor name"
                    height={60}
                    width={60}
                    className="rounded-full object-cover object-center"
                  />
                  <div className="max-w-[300px]">
                    <h1 className="w-full break-all text-[24px] font-semibold text-white-50">
                      {savedShow?.title?.substring(0, 20) +
                        `${savedShow?.title?.length > 20 ? "..." : ""}`}
                    </h1>
                    <div className="flex items-center gap-2">
                      <h4 className="text-[14px] font-semibold">
                        @{savedShow?.store?.businessUsername ?? ""}
                      </h4>
                    </div>
                    <span className="text-[12px] text-gray-500">
                      {storeData?.followers?.length ?? 0}{" "}
                      {storeData?.followers?.length === 1
                        ? "follower"
                        : "followers"}
                    </span>
                  </div>
                </div>
              </div>
            )}
            {/* control area actions  */}
            {savedShow &&
              getShowStatus(
                savedShow?.start_date,
                savedShow?.durationInSeconds,
                savedShow
              ) === "Live" &&
              playType === "stream" &&
              stream && (
                <div className="absolute bottom-[80px] left-1/2 z-[150] flex h-max w-4/5 -translate-x-1/2 items-center justify-center gap-16 rounded-3xl bg-black/50 py-4 pl-4 md:bottom-[20px] md:w-max md:justify-center md:gap-16 md:px-8">
                  {/* open and close mic */}
                  <button
                    onClick={() => {
                      stopAudioOnly(stream, micOpen ? "close" : "open");
                      setMicOpen(!micOpen);
                    }}
                    className="flex h-[50px] w-[50px] min-w-[50px] cursor-pointer items-center justify-center rounded-full border-[1px] border-[#f5f5f5] bg-black/30 md:h-[60px] md:w-[60px] md:min-w-[60px] md:bg-[#fff]"
                  >
                    {micOpen ? (
                      <Image
                        src="/assets/icons/micOn.svg"
                        height={26}
                        width={26}
                        alt="mic off"
                      />
                    ) : (
                      <Image
                        src="/assets/icons/micOff.svg"
                        height="26"
                        width="26"
                        alt="mic off"
                      />
                    )}
                  </button>

                  <div
                    id="start_stream"
                    onClick={() => {
                      startRec(streamID, stream);
                    }}
                    className="select animateLinkAsButton start_stream flex h-[70px] w-[70px] min-w-[70px] cursor-pointer items-center justify-center rounded-full bg-white-50"
                  >
                    <div className="flex h-[60px] w-[60px] items-center justify-center rounded-full bg-black md:bg-white-50">
                      <div className="h-[55px] w-[55px] rounded-full bg-red"></div>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      setShowConfirmModal(true);
                    }}
                    id="stop_stream"
                    style={{ display: "none" }}
                    className="select animateLinkAsButton stop_stream flex h-[70px] w-[70px] cursor-pointer items-center justify-center rounded-full bg-white-50"
                  >
                    <div className="flex h-[30px] w-[30px] items-center justify-center rounded-full bg-white-50">
                      <div className="h-[20px] w-[20px] bg-red"></div>
                    </div>
                  </div>

                  {/* open and close camera */}
                  <button
                    onClick={() => {
                      stopVideoOnly(stream, cameraOpen ? "close" : "open");
                      setCameraOpen(!cameraOpen);
                    }}
                    className="flex h-[50px] w-[50px] min-w-[50px] cursor-pointer items-center justify-center rounded-full border-[1px] border-[#f5f5f5] bg-black/30 md:h-[60px] md:w-[60px] md:min-w-[60px] md:bg-[#fff]"
                  >
                    {cameraOpen ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="#fe3232"
                        className="h-7 w-7 md:h-8 md:w-8"
                      >
                        <path d="M4.5 4.5a3 3 0 00-3 3v9a3 3 0 003 3h8.25a3 3 0 003-3v-9a3 3 0 00-3-3H4.5zM19.94 18.75l-2.69-2.69V7.94l2.69-2.69c.944-.945 2.56-.276 2.56 1.06v11.38c0 1.336-1.616 2.005-2.56 1.06z" />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="#686868"
                        className="h-6 w-6 md:h-8 md:w-8"
                      >
                        <path d="M3.53 2.47a.75.75 0 00-1.06 1.06l18 18a.75.75 0 101.06-1.06l-18-18zM22.5 17.69c0 .471-.202.86-.504 1.124l-4.746-4.746V7.939l2.69-2.689c.944-.945 2.56-.276 2.56 1.06v11.38zM15.75 7.5v5.068L7.682 4.5h5.068a3 3 0 013 3zM1.5 7.5c0-.782.3-1.494.79-2.028l12.846 12.846A2.995 2.995 0 0112.75 19.5H4.5a3 3 0 01-3-3v-9z" />
                      </svg>
                    )}
                  </button>
                </div>
              )}
            {/* end stream modal */}
            {showConfirmModal && (
              <div className="fixed inset-0 z-[130] flex h-full w-full items-center justify-center bg-black/50 backdrop:blur-md md:absolute">
                <div className="h-[200px] w-[400px] rounded-lg bg-white-50 px-8 py-4">
                  <div className="flex h-full flex-col justify-between">
                    <div className="flex flex-col gap-8">
                      <h1 className="text-center text-xl font-bold text-[#243669]">
                        End live show
                      </h1>
                      <p className="text-center text-sm text-[#243669]">
                        Are you sure you want to stop this live show? <br />
                      </p>

                      <div className="flex justify-center gap-4">
                        <button
                          onClick={() => setShowConfirmModal(false)}
                          className="text-white flex h-[40px] w-[100px] items-center justify-center rounded-md bg-[#243669] text-sm font-semibold"
                        >
                          Exit
                        </button>
                        <button
                          onClick={() => {
                            endShow();
                          }}
                          className="text-white ml-2 flex h-[40px] w-[120px] items-center justify-center rounded-md bg-[#fe3232] text-sm font-semibold"
                        >
                          {updating ? "Loading..." : "Confirm"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* products */}
          {!isMobile && (
            <div className="relative z-50 h-full w-[25%] bg-[#f5f5f5] pt-[70px] md:h-[calc(100%_-_50px)] md:pt-0">
              {/* open and close */}
              <div className="absolute left-0 z-[50] flex w-full flex-col items-start gap-0 bg-[#f5f5f5] px-0 py-2 md:w-full md:rounded-l-[40px]">
                <div className="hidden pl-4 md:block">
                  <h2 className="text-lg font-semibold text-black">Products</h2>
                  <p className="text-sm text-gray-400">
                    Products available for purchase
                  </p>
                </div>
                <div className="mt-2 h-[1px] min-h-[1px] w-full min-w-[100%] bg-gray-100"></div>
              </div>
              <div className="flex h-full w-full flex-col gap-4 overflow-y-scroll px-4 pr-[2px] pb-12 pt-4 md:h-[90vh] md:pt-[80px]">
                {isFetching ? (
                  <Spinner />
                ) : (
                  liveProducts?.map((pr) => (
                    <AdminViewProduct
                      key={pr._id}
                      image={pr.imageURL}
                      name={pr.name}
                      price={pr.price.$numberDecimal}
                      id={pr._id}
                      refreshShow={() => fetchShow()}
                      storeCurrency={storeData?.storeCurrency}
                    />
                  ))
                )}
              </div>
            </div>
          )}

          {/* logo and viewers */}
          <div className="absolute top-2 left-2 right-2 z-50 flex w-auto items-center justify-between gap-4 text-white-50 md:hidden">
            <Link href={`/${shopId}`} className="">
              <Image
                src="/logo.png"
                width={40}
                height={40}
                alt="TranzactWith"
              />
            </Link>
            {((playType === "stream" && showLive && !isLoading) ||
              (savedShow &&
                playType !== "stream" &&
                !isLoading &&
                getShowStatus(
                  savedShow?.start_date,
                  savedShow?.durationInSeconds ?? 5,
                  savedShow
                ) !== "Upcoming")) && (
              <div className="hidden w-max items-center justify-between rounded-3xl bg-white-50/50 py-2 px-2 md:flex">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="mr-1 h-6 w-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
                0
                <div className="ml-2 flex items-center gap-1">
                  <Image
                    src="/images/svgs/comments.svg"
                    width={20}
                    height={20}
                    alt="Comments"
                    className="mb-[0.2rem]"
                  />{" "}
                  <span className="font-medium">
                    {kFormater(messagesCount ?? 0)}
                  </span>
                </div>
                <span
                  className={`ml-2 h-4/5 w-max rounded-3xl bg-red px-3 py-1 font-bold ${
                    getShowStatus(
                      savedShow?.start_date,
                      savedShow?.durationInSeconds,
                      savedShow
                    ) === "Live"
                      ? "bg-red"
                      : "bg-gray-500"
                  }`}
                >
                  {getShowStatus(
                    savedShow?.start_date,
                    savedShow?.durationInSeconds,
                    savedShow
                  )}
                </span>
              </div>
            )}
          </div>

          {/* mobile tabs */}
          {activeTab === "" && activeTabMobile === "" && (
            <div
              className={`absolute right-4 z-30 flex flex-col items-center gap-4 rounded-md transition-all duration-300 ease-in md:hidden ${
                activeTab !== "" && activeTabMobile !== ""
                  ? "top-2"
                  : "bottom-[15rem]"
              }`}
            >
              <div
                onClick={() => {
                  // leaveRoom();
                  setActiveTabMobile("shop");
                  setActiveTab("shop");
                }}
                className="flex h-[45px] w-[45px] cursor-pointer items-center justify-center rounded-full bg-white-50/50"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={2}
                  stroke="currentColor"
                  className="h-6 w-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.75 10.5V6a3.75 3.75 0 10-7.5 0v4.5m11.356-1.993l1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 01-1.12-1.243l1.264-12A1.125 1.125 0 015.513 7.5h12.974c.576 0 1.059.435 1.119 1.007zM8.625 10.5a.375.375 0 11-.75 0 .375.375 0 01.75 0zm7.5 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
                  />
                </svg>
              </div>
              <div
                onClick={() => {
                  // leaveRoom();
                  setActiveTabMobile("details");
                  setActiveTab("details");
                }}
                className="flex h-[45px] w-[45px] cursor-pointer items-center justify-center rounded-full bg-white-50/50"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={2}
                  stroke="currentColor"
                  className="h-6 w-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M8.625 12a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </div>
              <div
                onClick={() => {
                  // joinRoom();
                  setActiveTabMobile("chat");
                  setActiveTab("chat");
                }}
                className="flex h-[45px] w-[45px] cursor-pointer items-center justify-center rounded-full bg-white-50/50"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={2}
                  stroke="currentColor"
                  className="h-6 w-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z"
                  />
                </svg>
              </div>
              <div
                onClick={() => openShareModal()}
                className="flex h-[45px] w-[45px] cursor-pointer items-center justify-center rounded-full bg-white-50/50"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={2}
                  stroke="currentColor"
                  className="h-6 w-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 8.25H7.5a2.25 2.25 0 00-2.25 2.25v9a2.25 2.25 0 002.25 2.25h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25H15m0-3l-3-3m0 0l-3 3m3-3V15"
                  />
                </svg>
              </div>
            </div>
          )}

          {/* chat and products */}
          {isMobile && activeTab !== "" && activeTabMobile !== "" && (
            <div
              className={`my-0 mt-[0.05rem] h-[60vh] min-h-[60vh] w-full min-w-[400px]  text-[#243669] md:h-full md:w-[20%] ${
                !isMobile
                  ? "relative"
                  : activeTab !== "" && activeTabMobile !== ""
                  ? "absolute top-[40vh] z-50 bg-white-50"
                  : "relative z-[5] bg-[#eaebef]"
              }`}
            >
              {/* two/three tabs - chat and products, details - WEB */}
              <div className="relative hidden h-[50px] w-auto rounded-md md:flex">
                <div
                  onClick={() => {
                    joinRoom();
                    setActiveTab("chat");
                    setActiveTabMobile("chat");
                  }}
                  className={`flex w-1/2 cursor-pointer justify-center border-b-[2px] border-r-gray-100 bg-[#eaebef] py-3 font-semibold text-[#243669] transition-all duration-300 ease-in  ${
                    activeTab === "chat"
                      ? " border-b-[#104553]"
                      : "border-b-[#eaebef]"
                  }`}
                >
                  <span>Chat</span>
                </div>
                <div className="absolute left-1/3 hidden h-full  w-[1px] bg-gray-100 md:left-1/2 md:block md:-translate-x-1/2" />
                <div
                  onClick={() => {
                    // leaveRoom();
                    setActiveTab("details");
                    setActiveTabMobile("details");
                  }}
                  className={`flex w-1/2 cursor-pointer justify-center border-b-[2px]  bg-[#eaebef] py-3 font-semibold text-[#243669] transition-all duration-300 ease-in md:hidden ${
                    activeTab === "details"
                      ? "border-b-[#104553]"
                      : "border-b-[#eaebef]"
                  }`}
                >
                  <span>Details</span>
                </div>
                <div className="absolute left-2/3 hidden h-full w-[1px] bg-gray-100 md:left-1/2 md:block md:-translate-x-1/2" />
                <div
                  onClick={() => {
                    // leaveRoom();
                    setActiveTab("shop");
                    setActiveTabMobile("shop");
                  }}
                  className={`flex w-1/2 cursor-pointer justify-center  border-b-[2px] bg-[#eaebef] py-3 font-semibold text-[#243669] transition-all duration-300 ease-in ${
                    activeTab === "shop"
                      ? "border-b-[#104553]"
                      : "border-b-[#eaebef]"
                  }`}
                >
                  <span>Shop</span>
                </div>
              </div>

              {/* products */}
              {(activeTab === "shop" || activeTabMobile === "shop") && (
                <div className="relative z-50 h-full bg-[#f5f5f5] pt-[70px] md:h-[calc(100%_-_50px)] md:pt-0">
                  <div className="absolute inset-x-0 top-0 flex h-[70px] w-full items-center justify-between border-b-[2px] border-[#e5e5e5] py-2 px-2 md:hidden">
                    <div>
                      <h2 className="text-2xl font-semibold">Products</h2>
                      <p className="text-sm text-gray-400">
                        Products available for purchase
                      </p>
                    </div>
                    <div
                      onClick={() => {
                        setActiveTabMobile("");
                        setActiveTab("");
                      }}
                      className="flex h-[35px] w-[35px] items-center justify-center rounded-full bg-black/50"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={2}
                        stroke="#fff"
                        className="h-4 w-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </div>
                  </div>
                  {/* open and close */}
                  <div className="absolute left-0 z-[50] flex w-full items-center gap-6 bg-[#f5f5f5] px-2 py-2 md:-left-8 md:w-[110%] md:rounded-l-[40px]">
                    <div className="hidden h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-full bg-[#eaebef] md:flex">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="#000"
                        className="h-6 w-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M8.25 4.5l7.5 7.5-7.5 7.5"
                        />
                      </svg>
                    </div>
                    <div className="hidden md:block">
                      <h2 className="text-lg font-semibold text-black">
                        Products
                      </h2>
                      <p className="text-sm text-gray-400">
                        Products available for purchase
                      </p>
                    </div>
                  </div>
                  <div className="flex h-full w-full flex-col gap-4 overflow-y-scroll bg-red px-4 pb-12 pt-4 md:h-[90vh] md:pt-[80px]">
                    {isFetching ? (
                      <Spinner />
                    ) : (
                      liveProducts?.map((pr) => (
                        <AdminViewProduct
                          key={pr._id}
                          image={pr.imageURL}
                          name="Nostrud aute non elit nulla esse exercitation ad. Ea labore irure veniam dolor voluptate. Commodo aute qui officia tempor ullamco cupidatat. Eu officia ea sunt sunt ea fugiat velit. Fugiat veniam nostrud veniam enim adipisicing anim duis exercitation aliquip elit et ullamco. Dolore id commodo nostrud labore commodo aliquip esse officia. Sunt ipsum ut adipisicing in elit esse in in in anim magna pariatur."
                          price={pr.price.$numberDecimal}
                          id={pr._id}
                          refreshShow={() => fetchShow()}
                        />
                      ))
                    )}
                  </div>
                </div>
              )}
              {/* details */}
              {(activeTab === "details" || activeTabMobile === "details") && (
                <div className="relative h-full bg-[#f5f5f5] pt-2 md:h-[calc(100%_-_50px)]">
                  <div
                    onClick={() => {
                      setActiveTabMobile("");
                      setActiveTab("");
                    }}
                    className="absolute right-4 top-4 flex h-[35px] w-[35px] items-center justify-center rounded-full bg-black/50"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={2}
                      stroke="#fff"
                      className="h-4 w-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </div>
                  <div className="mb-2 border-b-[2px] border-[#e5e5e5] pb-2">
                    <h2 className="px-2 text-2xl font-semibold">Details</h2>
                    <p className="px-2 text-sm  text-gray-400">
                      More info about the show
                    </p>
                  </div>
                  <div className="mt-4 -ml-4 flex w-full items-start gap-4 px-2">
                    <div className="relative h-[50px] w-[50px] min-w-[50px]">
                      <Image
                        src={`${
                          session?.user?.image ??
                          userProfile?.profilePictureURL ??
                          "/default.jpeg"
                        }`}
                        fill
                        alt="profile"
                        className="ml-4 rounded-full object-cover object-center"
                      />
                    </div>
                    <div className="relative flex w-full flex-col gap-2 pb-4 text-left">
                      <h1 className="w-full pl-4 text-[18px] font-semibold text-[#243669]">
                        {savedShow?.title}
                      </h1>
                      <div className="relative -mt-1 flex items-start gap-2 pl-4 md:mx-0">
                        <h4 className="text-[14px] font-semibold">
                          @{savedShow?.store?.businessUsername}
                        </h4>
                      </div>
                      <span className="pl-4 text-[12px] text-gray-500">
                        {storeData?.followers?.length ?? 0}{" "}
                        {storeData?.followers?.length === 1
                          ? "follower"
                          : "followers"}
                      </span>
                      <div className="absolute right-0 left-[-65px] bottom-0 h-[1px] w-[140%] bg-[#dcdcdc]" />
                      <p className="absolute bottom-[-40px] left-[-45px] mt-8 w-full text-sm font-medium text-gray-500">
                        {savedShow?.description}
                      </p>
                    </div>
                  </div>
                </div>
              )}

              {/* chat */}
              {(activeTab === "chat" || activeTabMobile === "chat") && (
                <div className="block h-full w-full overflow-hidden bg-transparent md:block md:h-[calc(100%_-_50px)]">
                  <div className="relative flex h-full w-full flex-col-reverse justify-start pb-0 md:flex-col md:pb-0">
                    {/* user chats */}
                    <div
                      id="chatScroll"
                      className="no-scrollbar relative flex h-full w-full flex-col gap-6 overflow-y-scroll rounded-md py-6 pb-12"
                    >
                      {messagesRecieved.length === 0 ? (
                        <div className="absolute top-[40%] left-1/2 flex w-4/5 -translate-x-1/2 flex-col items-center gap-4 rounded-lg p-4 text-lg font-bold text-black">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="#104553"
                            className="h-12 w-12"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M12 20.25c4.97 0 9-3.694 9-8.25s-4.03-8.25-9-8.25S3 7.444 3 12c0 2.104.859 4.023 2.273 5.48.432.447.74 1.04.586 1.641a4.483 4.483 0 01-.923 1.785A5.969 5.969 0 006 21c1.282 0 2.47-.402 3.445-1.087.81.22 1.668.337 2.555.337z"
                            />
                          </svg>
                          <span>No messages here yet</span>
                        </div>
                      ) : (
                        messagesRecieved?.map((chat) => (
                          <div
                            key={chat?._id}
                            id={chat?._id}
                            className="flex w-full items-center gap-2"
                          >
                            <OneEntry
                              userName={chat.username}
                              profileImage={
                                chat?.profilePicture?.length > 0
                                  ? chat?.profilePicture
                                  : `/default.jpeg`
                              }
                              message={chat.message}
                              replyingTo={chat?.replyingTo}
                              time={chat.createdAt}
                              isVendor={chat?.isVendor}
                              replyMessage={() => {
                                setReplyingTo({
                                  message: chat?.message,
                                  username: chat?.username,
                                  _id: chat?._id,
                                });
                                focusOnInput();
                              }}
                            />
                          </div>
                        ))
                      )}
                      <div id="bacon" className="" />
                    </div>
                    {/* chat entry for admin */}
                    <form
                      className={`flex h-max w-full items-center gap-6 bg-transparent py-1 pt-0 md:static md:bottom-0 md:h-[50px] md:w-full md:px-2 md:pb-4 ${
                        replyingTo ? "mb-8" : "mb-0"
                      }`}
                    >
                      {getShowStatus(
                        savedShow?.start_date,
                        savedShow?.durationInSeconds,
                        savedShow
                      ) === "Was Live" ? (
                        <div className="relative -mt-7 flex h-[50px] w-full items-center gap-4 rounded-lg bg-[#F3F3F3] px-4 font-medium text-gray-300">
                          <p className="text-sm">
                            Live chat is disabled after a show has aired.
                          </p>
                          <div
                            onClick={() => {
                              setActiveTabMobile("");
                              setActiveTab("");
                            }}
                            className="absolute right-2 flex h-[35px] w-[35px] items-center justify-center rounded-full bg-black/50 md:hidden"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={2}
                              stroke="#fff"
                              className="h-4 w-4"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                              />
                            </svg>
                          </div>
                        </div>
                      ) : (
                        <div
                          className={`relative flex w-full flex-col items-center gap-0 border-b-[2px] border-[#f5f5f5] px-2 pt-[4rem] md:w-full md:pt-2 ${
                            replyingTo ? "bg-[#0076bd]" : "bg-white-50"
                          }`}
                        >
                          <div className="absolute inset-x-0 top-0 flex h-max w-full items-center justify-between border-b-[2px] border-[#f5f5f5] py-3 px-2 md:hidden">
                            <h2 className="text-2xl font-semibold">Comments</h2>
                            <div
                              onClick={() => {
                                setActiveTabMobile("");
                                setActiveTab("");
                              }}
                              className="flex h-[35px] w-[35px] items-center justify-center rounded-full bg-black/50"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={2}
                                stroke="#fff"
                                className="h-4 w-4"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M6 18L18 6M6 6l12 12"
                                />
                              </svg>
                            </div>
                          </div>
                          {replyingTo && (
                            <div className="flex h-[50px] w-[calc(100%_-_10px)] bg-white-50 p-2 text-gray-300">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 48 48"
                                id="reply"
                                className="text-[#0076bd]"
                                stroke="currentColor"
                              >
                                <path
                                  fill="currentColor"
                                  d="M20 18v-8L6 24l14 14v-8.2c10 0 17 3.2 22 10.2-2-10-8-20-22-22z"
                                ></path>
                              </svg>
                              <div className="flex flex-col">
                                <span className="flex items-center text-xs font-semibold">
                                  {replyingTo?.username}
                                </span>
                                <p className="w-full !text-base font-normal md:w-[100%]">
                                  {replyingTo?.message.length > 30 ? (
                                    <span>
                                      {replyingTo?.message.slice(0, 30)}
                                    </span>
                                  ) : (
                                    <span>{replyingTo?.message}</span>
                                  )}
                                </p>
                              </div>
                            </div>
                          )}
                          <div className="border-round flex w-full items-center gap-2 py-2">
                            <div className="relative h-[40px] w-[40px] min-w-[40px]">
                              <Image
                                src={`${
                                  userProfile?.profilePictureURL?.length > 0
                                    ? userProfile?.profilePictureURL
                                    : `${
                                        session?.user?.image ?? "/default.jpeg"
                                      }`
                                }`}
                                fill
                                alt="profile"
                                className="rounded-full object-cover object-center"
                              />
                            </div>
                            <input
                              type="text"
                              id="message"
                              name="message"
                              disabled={
                                getShowStatus(
                                  savedShow?.start_date,
                                  savedShow?.durationInSeconds,
                                  savedShow
                                ) === "Was Live"
                              }
                              value={message}
                              onChange={(e) => setMessage(e.target.value)}
                              placeholder={
                                replyingTo ? "Reply..." : "Type a message..."
                              }
                              className={`common-input h-[40px] w-[100%] rounded-md text-[#243669] ${
                                replyingTo
                                  ? "common-input-live-reply"
                                  : "common-input-live"
                              }`}
                            />
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                sendMessage();
                              }}
                              disabled={!message}
                              className="text-white absolute right-[10px] flex h-[35px] w-[35px] min-w-[35px] items-center justify-center rounded-full bg-[#104553] font-semibold text-white-50"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="h-6 w-6"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5"
                                />
                              </svg>
                            </button>
                          </div>
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              )}
            </div>
          )}

          <AnimatePresence>
            {messageNotifications?.length > 0 && (
              <div className="no-scrollbar fixed left-2 bottom-[15vh] z-20 flex h-max w-[80vw] flex-col gap-4 overflow-y-auto rounded-lg py-8 pt-4 md:hidden">
                {messageNotifications?.map((chat) => (
                  <motion.div
                    key={chat.createdAt}
                    id={chat.createdAt}
                    initial={{ opacity: 0, y: 100 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                    exit={{ opacity: 0, y: -100 }}
                    className="flex w-full items-center gap-2"
                  >
                    <Notification
                      userName={chat.username}
                      profileImage={
                        chat?.profilePicture?.length > 0
                          ? chat?.profilePicture
                          : `/default.jpeg`
                      }
                      message={chat.message}
                      fromNotif
                    />
                  </motion.div>
                ))}
              </div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
}

function AdminViewProduct({
  name,
  image,
  price,
  id,
  refreshShow,
  storeCurrency,
}) {
  const dispatch = useDispatch();
  const savedShow = useSelector((state) => state.show.savedShow);
  const [updateShowProducts] = useUpdateShowInfoMutation();

  const toggleProduct = async (productId) => {
    const obj = {
      products: savedShow?.products?.filter((id) => id !== productId),
    };
    const showId = savedShow._id;
    await updateShowProducts({ obj, showId })
      .unwrap()
      .then((payload) => {
        dispatch(
          setSavedShow({
            ...savedShow,
            products: payload.products,
          })
        );
        refreshShow();
      })
      .catch(() => {
        notification({
          status: "error",
          message: "An error occured while updating show products",
        });
      });
  };

  return (
    <div className="relative mx-auto flex h-auto w-[100%] items-center justify-between gap-4 rounded border-[1px] border-gray-100 bg-white-50 py-2">
      <div className="flex h-full w-full items-center gap-4">
        {name ? (
          <div className="relative ml-2 h-full w-[5rem] min-w-[5rem] md:w-[7rem] md:min-w-[7rem]">
            <img
              src={image}
              style={{
                height: "100%",
                width: "100%",
                objectFit: "cover",
                objectPosition: "center",
              }}
              alt="User"
              className="rounded object-contain"
            />
          </div>
        ) : (
          <div className="h-full w-[4rem] min-w-[4rem] animate-pulse rounded-3xl bg-gray-400" />
        )}
        <div className="flex h-full w-full flex-col justify-between gap-8">
          {name ? (
            <h2 className="h-max !text-sm font-semibold text-black">
              {name?.length > 100 ? name?.slice(0, 100) + "..." : name}
            </h2>
          ) : (
            <div className="h-[40px] w-4/5 animate-pulse rounded bg-gray-400" />
          )}
          <div className="flex w-full justify-between">
            {name ? (
              <p className="!text-sm font-bold text-gray-300">
                {storeCurrency?.split("-")[0] ?? "$"} {price}
              </p>
            ) : (
              <div className="h-[30px] w-4/5 animate-pulse rounded bg-gray-400" />
            )}
            {getShowStatus(
              savedShow?.start_date,
              savedShow?.durationInSeconds,
              savedShow
            ) !== "Was Live" && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="#f40101"
                className="mr-2 h-6 w-6 cursor-pointer"
                onClick={() => toggleProduct(id)}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                />
              </svg>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function OneEntry({
  userName,
  profileImage,
  message,
  time,
  replyMessage,
  replyingTo,
  isVendor,
}) {
  return (
    <div
      className={`group relative flex w-[calc(100%_-_20px)] gap-2 py-1 ${
        replyingTo?.message
          ? "ml-2 flex-col bg-[#0076bd] p-2"
          : "flex-row bg-transparent p-0"
      }`}
    >
      {replyingTo?.message && (
        <div className="flex h-[50px] w-full bg-[#f5f5f5] p-2 text-gray-600">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 48 48"
            id="reply"
            className="text-[#0076bd]"
            stroke="currentColor"
          >
            <path
              fill="currentColor"
              d="M20 18v-8L6 24l14 14v-8.2c10 0 17 3.2 22 10.2-2-10-8-20-22-22z"
            ></path>
          </svg>
          <div className="flex flex-col">
            <span className="flex items-center text-xs font-semibold">
              {replyingTo?.username}
            </span>
            <p className="w-full !text-base font-normal md:w-[100%]">
              {replyingTo?.message?.length > 40 ? (
                <span>{replyingTo?.message.slice(0, 40)}...</span>
              ) : (
                <span>{replyingTo?.message}</span>
              )}
            </p>
          </div>
        </div>
      )}
      <div
        className={`flex w-full gap-2 ${
          replyingTo?.message ? "text-white-50" : "text-gray-600"
        }`}
      >
        <div
          className={`h-[40px] w-[40px] bg-white-50 rounded-full min-w-[40px] shrink-0 ${
            replyingTo?.message ? "ml-0" : "ml-2"
          }`}
        >
          {isVendor ? (
            <Image
              src={profileImage}
              height={40}
              width={40}
              alt={userName}
              className="rounded-full"
            />
          ) : (
            <img
              src={`https://api.dicebear.com/6.x/initials/svg?seed=${userName}&radius=50&fontWeight=500&scale=75`}
              alt="User"
              className="rounded-full object-cover object-top"
              style={{
                height: "100%",
                width: "100%",
                objectFit: "cover",
                objectPosition: "top",
              }}
            />
          )}
        </div>
        <div className="flex w-full max-w-[85%] flex-col gap-0">
          <div className="flex w-full items-center justify-between gap-2">
            <h2
              className={`text-[14px] font-bold  ${
                replyingTo?.message ? "text-white-50" : "text-gray-600"
              }`}
            >
              {userName}
            </h2>
            <div className="flex gap-2 text-[10px]">
              <span
                className={`text-white-50 ${
                  replyingTo?.message ? "md:text-white-50" : "md:text-gray-600"
                }`}
              >
                {formatDuration(time)}
              </span>
            </div>
          </div>
          <OneComment comment={message} />
        </div>
      </div>

      <svg
        onClick={() => replyMessage()}
        xmlns="http://www.w3.org/2000/svg"
        width="28"
        height="28"
        viewBox="0 0 48 48"
        id="reply"
        className={`visible absolute cursor-pointer md:invisible md:group-hover:visible ${
          replyingTo?.message
            ? "right-5 bottom-1 text-white-50 "
            : "right-2 bottom-1 text-[#0076bd] "
        }`}
        stroke="currentColor"
      >
        <path
          fill="currentColor"
          d="M20 18v-8L6 24l14 14v-8.2c10 0 17 3.2 22 10.2-2-10-8-20-22-22z"
        ></path>
      </svg>
    </div>
  );
}
function Notification({ userName, profileImage, message }) {
  return (
    <div className="flex w-max max-w-[80%] items-center gap-2 rounded-3xl bg-black/30 px-2 py-1 pr-4">
      <div className="min-w-[30px]">
        <Image
          src={profileImage}
          height={30}
          width={30}
          alt={userName}
          className="rounded-full"
        />
      </div>
      <div className="flex w-max max-w-[85%] gap-2">
        <OneCommentNotif comment={message} username={userName} />
      </div>
    </div>
  );
}

export function OneComment({ comment }) {
  return (
    <div className="flex w-max max-w-full items-center gap-3 rounded-b-[16px] rounded-tr-[16px] bg-transparent px-6 py-1 pt-0 pl-0">
      <p className="w-max text-[14px] font-medium leading-relaxed md:text-current">
        {comment}
      </p>
    </div>
  );
}

export function OneCommentNotif({ comment, username }) {
  return (
    <div className="flex gap-2 text-[14px] font-medium leading-relaxed text-white-50 md:text-[#243669]">
      <p>
        <span className="text-[14px] font-bold">{username}</span>{" "}
        {comment?.substring(0, 45) + `${comment.length > 45 ? "..." : ""}`}
      </p>
    </div>
  );
}

export const thousandSeparator = (value) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const formatToK = (num) => {
  if (num === 0) return num;
  const suffixes = ["", "K", "M", "B"];
  const magnitude = Math.floor(Math.log10(Math.abs(num)) / 3);
  const scaledNumber = num / Math.pow(10, magnitude * 3);
  const formattedNumber = scaledNumber.toFixed(2);
  // if last 2 digits are 0 after converting to fixed, remove them
  if (formattedNumber.slice(-2) === "00")
    return formattedNumber.slice(0, -3) + suffixes[magnitude];
  return formattedNumber + suffixes[magnitude];
};
