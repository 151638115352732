import Prism from "prismjs";
import { useEffect } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
// import { solarizedlight } from "react-syntax-highlighter/dist/esm/styles/prism";
// const vscDarkPlus = dynamic(
//   () => import("react-syntax-highlighter/dist/esm/styles/prism"),
//   { ssr: false }
// );
import "prismjs/components/prism-javascript";

import "prismjs/themes/prism.css";

const CodeSnippet = ({ language, code }) => {
  useEffect(() => {
    Prism.highlightAll();
  }, []);

  return (
    <SyntaxHighlighter language={language}>
      {code}
    </SyntaxHighlighter>
  );
};

export default CodeSnippet;
