import Hls from "hls.js";
import React, { useEffect, useRef } from "react";
import videojs from "video.js";
import "./components/hlsjs.js";
// import "./components/nuevo.js";
import "./components/nuevo.js";
import "./components/offline.js";

const VideoPlayer = (props) => {
  const videoNode = useRef(null);
  let player;

  useEffect(() => {
    player = videojs(videoNode.current, props, function onPlayerReady() {
      console.log("");
    });

    player.poster(props.poster);

    player.on("error", (err) => {
      console.log(err);
    });

    const nuevoOptions = {
      contextMenu: false,
      logo: "https://ik.imagekit.io/tzwassets/tzw/tzw-beta-transparent-logo.png?tr=w-50",
      logourl: "https://tranzactwith.com",
    };

    player.nuevo(nuevoOptions);
    player.offline({
      offlineImage: props.poster,
      offlineTimeout: 10,
      offlineCountdown: false,
      label: "Restart",
    });

    var callback = function (videojsPlayer, hlsjs) {
      hlsjs.on(Hls.Events.MEDIA_ATTACHED, function (event, data) {
        console.log("");
      });
    };
    videojs.Html5Hlsjs.addHook("beforeinitialize", callback);
    player.muted(true);
    player.src({
      src: props.src,
      type: "application/x-mpegURL",
    });

    return () => {
      if (player) {
        player.dispose();
      }
    };
  }, [props.src]);

  return (
    <div className="container relative z-30 h-full w-full md:z-20">
      <div data-vjs-player className="h-full w-full">
        <video onContextMenu={(e) => e.preventDefault()} ref={videoNode} className="video-js vjs-fluid"></video>
      </div>
    </div>
  );
};

export default VideoPlayer;
