import { Spinner } from "components/common/spinner";
import { MAX_FILE_SIZE } from "components/settings/personal";
import Image from "next/image";
import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";

export interface ShowInterface {
  title: string;
  description: string;
  date: string;
  eventType: "liveparty" | "liveshow";
  showPrivacy: "public" | "private";
  showOnlineUsers: "yes" | "no";
  allowChat: "yes" | "no";
  canUseGuestMode: "yes" | "no";
  image: File;
  videoLink: string;
}

import { useSelector } from "react-redux";

export default function StepFour({
  saveData,
  loading,
  image,
}: {
  saveData: (data: any) => void;
  loading: boolean;
  image: File | string | null;
}) {
  const [images, setImages] = useState<any[]>([]);
  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    accept: {
      "image/jpeg": [],
      "image/png": [],
      "image/jpg": [],
    },
    maxSize: MAX_FILE_SIZE * 2,
    onDrop: async (acceptedFiles) => {
      const renamedAcceptedFiles = acceptedFiles.slice(0, 1).map(
        (file) =>
          new File([file], `${new Date()}_${file.name}`, {
            type: file.type,
          })
      );
      if (renamedAcceptedFiles[0]) {
        const newFile = renamedAcceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );

        setImages([...images, ...newFile]);
      }
    },
  });

  const removeImage = () => {
    setImages([]);
  };

  const savedShow = useSelector((state: any) => state.show.savedShow);
  const [videoFile, setVideoFile] = useState<File | null | string>(null);

  useEffect(() => {
    if (images.length > 0) {
      saveData({
        image: images[0],
        videoFile: videoFile ?? undefined,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images]);

  useEffect(() => {
    if (image) {
      setImages([image]);
    }
  }, [image]);

  useEffect(() => {
    if (!(images[0] instanceof File)) {
      if (savedShow?.imageURL) {
        setImages([savedShow?.imageURL]);
      }
    }
    if (!(videoFile instanceof File)) {
      if (savedShow?.videoURL) {
        setVideoFile(savedShow?.videoURL);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedShow]);

  const [exceedsSize, setExceedsSize] = useState(false);

  const getVideoDuration = (file: File) => {
    const video = document.createElement("video");
    video.preload = "metadata";
    video.onloadedmetadata = function () {
      window.URL.revokeObjectURL(video.src);
    };
    video.src = URL.createObjectURL(file);
  };

  const handleVideoFile = (e: any) => {
    setExceedsSize(false);
    // max file size 300mb
    if (e.target.files[0].size > 300000000) {
      setExceedsSize(true);
      return;
    }
    setExceedsSize(false);
    setVideoFile(e.target.files[0]);
    getVideoDuration(e.target.files[0]);
  };

  return (
    <div className="flex flex-col gap-8 mb-2 md:w-full md:flex-row md:pr-4">
      <div className="mt-0 w-[350px]">
        <p className="-mb-6 font-medium">Featured Image</p>
        {images.length > 0 ? (
          <div className="relative mt-8 h-[160px] w-full rounded-xl overflow-hidden border-[1px] border-gray-100 md:w-full md:min-w-[100%]">
            <Image
              src={images[0].preview ?? images[0]}
              fill
              alt="Uploaded Image"
              className="rounded-lg object-cover bg-white-50 object-center"
            />
            <div
              onClick={() => removeImage()}
              className="absolute top-1 right-1 flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-full bg-black/70"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="#fff"
                className="h-6 w-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </div>
          </div>
        ) : (
          <div
            className="input-file !focus:border-[2px] !focus:border-[#7F82BA] mt-8 flex h-[160px] w-full flex-col items-center justify-center gap-6 rounded-lg border-[2px] border-[#7F82BA] px-8 text-center"
            {...getRootProps()}
          >
            <input {...getInputProps()} className="input-file" />
            <div className="flex h-[50px] w-[50px] items-center justify-center rounded-full bg-white-50">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="#000"
                className="h-6 w-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 4.5v15m7.5-7.5h-15"
                />
              </svg>
            </div>
            <div>
              {fileRejections.length > 0 ? (
                <span className="text-[14px] font-medium text-red">
                  Unsupported image format, only png, jpg and jpeg allowed{" "}
                  <br />
                  Max image size of 10mb
                </span>
              ) : (
                <p className="text-[14px] font-medium leading-[160%] text-[#7F82BA]">
                  Drag & drop or click to add main show image JPEG, JPG or PNG,
                  no larger than 10MB
                </p>
              )}
            </div>
          </div>
        )}
      </div>
      {loading && (
        <div className="fixed inset-0 z-50 flex h-full w-full items-center justify-center rounded-md bg-black/70 backdrop-blur-sm md:absolute">
          <div className="rounded-md bg-white-50 px-4 py-2 pt-4 text-center text-[#243669]">
            <p className="text-lg font-bold">Uploading media ...</p>
            <span className="text-xs">
              This may take a short while depending on the file sizes and your
              internet connection speed
            </span>
            <Spinner />
          </div>
        </div>
      )}
    </div>
  );
}
