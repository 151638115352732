// @ts-ignore
import DropDown from "components/auth/dropdown";
// @ts-ignore
import AuthModal from "components/auth/modal";
// @ts-ignore
// @ts-ignore
import BottomNav from "components/routes/landing/bottomNav/nav";
// @ts-ignore
import MainDashboard from "components/studio/main";
import { useRouter } from "next/router";
import { signOut } from "next-auth/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetAuth, setUserProfile } from "redux/auth/authSlice";

import Header from "../header";
import { useGetUserByIdQuery } from "services/baseApiSlice";
export default function Index(props: any) {
  const { children } = props;

  const dispatch = useDispatch();

  const router = useRouter();

  const { streamID } = router.query;

  const [showAuthModal, setShowAuthModal] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  const userProfile = useSelector((state: any) => state.auth.userProfile);

  const id = userProfile?._id;

  const { currentData } = useGetUserByIdQuery(id, {
    skip: !id,
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
  });

  useEffect(() => {
    if (currentData) {
      dispatch(setUserProfile(currentData));
    }
  }, [currentData]);

  return (
    <div className={`no-scrollbar relative z-[999] w-full overflow-hidden `}>
      {/* <Header /> */}
      <div>
        <div>
          {!props.oldHeaderOnMobile && (
            <Header
              showDropDown={() => setShowDropdown(!showDropdown)}
              showAuthModal={() => setShowAuthModal(!showAuthModal)}
            />
          )}
          <MainDashboard
            activeIndex={props.activeIndex}
            openSide={(val: boolean) => props.setSideOpen(val)}
            setSearchResults={(val: any) => props.setSearchResults(val)}
            data={props.data}
            searchValuePass={(val: string) => props.setSearchValue(val)}
            hideByDefaultSide={props.hideByDefaultSide}
            hideSearch={props.hideSearch}
            showCreateShow={props.showCreateShow}
            editId={props.editId}
            resetEditId={props.setEditId}
            setRefetch={props.setRefetch}
            openDropdown={props.openDropdown}
            resetDropDown={props.resetDropDown}
          />
          <main
            key={
              // @ts-ignore
              router.asPath
            }
            className="no-scrollbar"
          >
            {children}
          </main>
        </div>
      </div>
      {!streamID && <BottomNav />}
      {showAuthModal && <AuthModal />}
      {showDropdown && (
        <DropDown
          closeModal={() => setShowDropdown(false)}
          signOut={() => {
            dispatch(resetAuth());
            signOut({ redirect: true, callbackUrl: "/" });
            setShowDropdown(false);
          }}
        />
      )}
    </div>
  );
}
