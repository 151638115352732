import notification from "components/common/notification";
import { getShowStatus } from "components/routes/livestream/utils";
import Image from "next/image";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setCompletedSteps,
  setSavedShow,
  setSelectedVideo,
} from "redux/show/showSlice";
import {
  useGetShowInfoMutation,
  useGetShowInfoUsingStreamIDMutation,
  useSaveShowInfoMutation,
  useUpdateShowInfoMutation,
  useUploadShowMediaMutation,
} from "services/baseApiSlice";

import CodeSnippet from "./codeSnippet";
import StepOne from "./step1";
import StepTwo from "./step2";
import StepFour from "./step4";
import ChatToggleBox from "./toggleCheckBox";

export interface ShowInterface {
  title: string;
  description: string;
  date: string;
  start_date: string;
  eventType: "liveparty" | "liveshow";
  showPrivacy: "public" | "private";
  showOnlineUsers: "yes" | "no";
  allowChat: "yes" | "no";
  canUseGuestMode: "yes" | "no";
  image: File | string;
  videoLink: string;
  video: File;
  mediaURL: string;
  durationInSeconds: number;
  streamType: string;
}

export default function CreateShowModal({
  closeModal,
  editingId,
}: {
  closeModal: (val: boolean) => void;
  editingId?: string;
}) {
  const dispatch = useDispatch();
  const router = useRouter();
  const { type, shopId } = router.query;

  const [saveShow, { isLoading }] = useSaveShowInfoMutation();
  const [updateShow, { isLoading: updating }] = useUpdateShowInfoMutation();
  const [uploadItems, { isLoading: uploading }] = useUploadShowMediaMutation();

  const savedShow = useSelector((state: any) => state.show.savedShow);

  const completedSteps = useSelector((state: any) => state.show.completedSteps);

  const [showData, setShowData] = useState<ShowInterface>();

  const [getShow, { isLoading: loadingShow }] =
    useGetShowInfoUsingStreamIDMutation();
  const [getShowWithId] = useGetShowInfoMutation();

  const businessProfile = useSelector(
    (state: any) => state.auth.businessProfile
  );
  const userProfile = useSelector((state: any) => state.auth.userProfile);

  useEffect(() => {
    if (userProfile && !businessProfile) {
      router.push("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessProfile, userProfile]);

  const [activeStep, setActiveStep] = useState("1");

  const [showType, setShowType] = useState<"onsite" | "offsite" | undefined>();

  const selectedVideo = useSelector((state: any) => state.show.selectedVideo);

  const fetchShow = async () => {
    if (editingId) {
      const showId = editingId;
      await getShow({ showId })
        .unwrap()
        .then((payload) => {
          dispatch(setSavedShow(payload));
          if (payload?.streamType) {
            setShowType(payload?.streamType);
          }
        })
        .catch(() => {
          notification({
            status: "error",
            message: "An error occured while fetching show details",
          });
        });
    } else {
      const showId = savedShow?._id;
      if (!showId) return;
      await getShowWithId({ showId })
        .unwrap()
        .then((payload: any) => {
          dispatch(setSavedShow(payload));
          if (payload?.streamType) {
            setShowType(payload?.streamType);
          }
        })
        .catch((error) => {
          notification({
            status: "error",
            message:
              error.data.message ??
              "An error occured while fetching show details",
          });
        });
    }
  };

  const [selectedGoLive, setSelectedGoLive] = useState(false);
  const [hasTouchedDate, setHasTouchedDate] = useState(false);

  useEffect(() => {
    if (savedShow?.selectedGoLive) {
      setSelectedGoLive(savedShow?.selectedGoLive);
    }
  }, [savedShow]);

  const saveStepOne = async (body: ShowInterface) => {
    // check if hasTouchedDate is false, then set body.start_date to undefined
    if (!hasTouchedDate && editingId) {
      // @ts-ignore
      body.start_date = undefined;
    }
    // @ts-ignore
    body.streamType = type;
    body.mediaURL = selectedVideo?.videoURL ?? undefined;
    const showId = savedShow._id;
    if (showId || editingId) {
      // edit mode: fields: title, description, date, eventType
      const obj = {
        title: body?.title?.length > 0 ? body?.title : undefined,
        description:
          body?.description?.length > 0 ? body?.description : undefined,
        start_date: body?.start_date ?? undefined,
        eventType: body?.eventType,
      };
      const showId = savedShow._id || editingId;

      // if editingId is present, do not allow update till the date is changed to future
      if (editingId) {
        const currentDate = new Date().getTime();
        const dateInBody = new Date(body.start_date).getTime();
        const showDate = new Date(savedShow.start_date).getTime();

        if (!body?.start_date && !(showDate > currentDate)) {
          notification({
            status: "error",
            message: "Update the show date to a future date.",
          });
          return;
        }

        if (dateInBody < currentDate) {
          if (selectedGoLive) {
            console.log("");
          } else if (showDate > currentDate) {
            console.log("");
          } else {
            // check if dateInBody is not greater than now
            notification({
              status: "error",
              message: "Update the show date to a future date",
            });
            return;
          }
        }
      }
      await updateShow({ obj, showId })
        .unwrap()
        .then((payload) => {
          dispatch(setSavedShow({ ...savedShow, ...payload }));
          if (payload?.streamType) {
            setShowType(payload?.streamType);
          }
          fetchShow();
          if (showData?.image instanceof File) {
            saveMediaInfo(payload?._id);
          } else {
            dispatch(
              setSavedShow({
                ...savedShow,
                selectedGoLive,
              })
            );
            setActiveStep("2");
          }
        })
        .catch(() => {
          notification({
            status: "error",
            message: "An error occured while editing step 1 details",
          });
        });
    } else {
      // check if we have name, description, date, eventType, if any is missing, return
      if (
        !body?.title ||
        !body?.description ||
        !body?.start_date ||
        !body?.image
      ) {
        notification({
          status: "error",
          message: "Please fill all the fields",
        });
        return;
      }
      await saveShow({ body })
        .unwrap()
        .then((payload) => {
          dispatch(setSavedShow(payload));
          if (payload?.streamType) {
            setShowType(payload?.streamType);
          }
          if (!completedSteps.includes(1)) {
            dispatch(setCompletedSteps([...completedSteps, 1]));
          }
          if (showData?.image instanceof File) {
            saveMediaInfo(payload?._id);
          } else {
            dispatch(
              setSavedShow({
                ...savedShow,
                selectedGoLive,
              })
            );
            setActiveStep("2");
          }
        })
        .catch((error) => {
          notification({
            status: "error",
            message:
              error?.data?.message ??
              "An error occured while saving step 1 details",
          });
        });
    }
  };

  const goLive = () => {
    if (savedShow?.products?.length === 0) {
      notification({
        status: "error",
        message: "Please select products",
      });
      return;
    }
    if (editingId) {
      router.replace(`/${shopId}/stream/` + editingId);
      return;
    }
    if (!completedSteps.includes(1)) {
      notification({
        status: "error",
        message: "Please add show details in step 1",
      });
      return;
    }
    router.replace(`/${shopId}/stream/` + savedShow.streamID);
  };

  const saveMediaInfo = async (showID: string) => {
    if (!showID) {
      notification({
        status: "error",
        message: "Please add show details in step 1 first",
      });
      return;
    }
    const formData = new FormData();
    if (showData?.image || showData?.video) {
      if (typeof showData?.image === "string") {
        return;
      } else {
        formData.append(
          "folder",
          `stores/${businessProfile?.businessUsername}/shows/`
        );
        if (showData.image instanceof File) {
          formData.append("files", showData.image);
          formData.append("durationInSeconds", showData.durationInSeconds + "");
          if (savedShow?.imageFileId?.length > 0) {
            formData.append("deleteShowImage", savedShow?.imageFileId);
          }
        }
      }
    } else {
      notification({
        status: "error",
        message: "Please upload show image",
      });
      return;
    }
    const showId = showID;
    await uploadItems({ formData, showId })
      .unwrap()
      .then((payload) => {
        dispatch(setSavedShow({ ...savedShow, ...payload, selectedGoLive }));
        setActiveStep("2");
      })
      .catch(() => {
        notification({
          status: "error",
          message: "An error occured while saving image",
        });
      });
  };

  const closeModalAndClear = () => {
    dispatch(setSelectedVideo(undefined));
    dispatch(setSavedShow({}));
    closeModal && closeModal(false);
  };

  const nextHandler = () => {
    if (activeStep === "1") {
      // new data
      if (
        showData &&
        (showData?.title || showData?.description || showData?.start_date)
      ) {
        const body = {
          ...showData,
          userId: userProfile?._id,
        };
        const removedImage = {
          ...body,
          image: body.image instanceof File ? body.image : undefined,
        };
        // @ts-ignore
        saveStepOne(removedImage);
      }
      // we have data saved, going to next step
      else if (
        savedShow &&
        savedShow?.title &&
        savedShow?.description &&
        savedShow?.imageURL
      ) {
        if (showData?.image instanceof File) {
          saveMediaInfo(savedShow?._id);
        } else {
          setActiveStep("2");
        }
      } else {
        notification({
          status: "error",
          message: "Please add show details",
        });
      }
    } else if (activeStep === "2") {
      if (savedShow?.products?.length > 0) {
        // check savedShow.start_date. If it is in the past or now, go to live page, calling goLive()
        // if it is in the future, go to /shopId/shows

        // check if start_date is greater than now + 3 minutes

        setActiveStep("3");
      } else {
        notification({
          status: "error",
          message: "Please select products",
        });
      }
    } else if (activeStep === "3") {
      const currentDate = new Date().getTime();
      const dateInBody = new Date(savedShow?.start_date).getTime();
      if (dateInBody < currentDate + 3 * 60 * 1000) {
        goLive();
        return;
      }
      const showStatus = getShowStatus(
        savedShow?.start_date,
        savedShow?.durationInSeconds ?? 5,
        savedShow
      );

      if (showStatus === "Upcoming") {
        notification({
          status: "success",
          message: "Your show has been scheduled successfully!",
        });
        dispatch(setSavedShow({}));
        closeModal && closeModal(true);
      } else {
        goLive();
      }
    }
  };
  const prevHandler = () => {
    if (activeStep === "1") {
      dispatch(setSavedShow({}));
      closeModal && closeModal(false);
      return;
    }
    if (activeStep === "2") {
      // go to next step
      setActiveStep("1");
    }
    if (activeStep === "3") {
      // go to next step
      setActiveStep("2");
    }
    if (activeStep === "4") {
      // go to next step
      setActiveStep("3");
    }
  };

  useEffect(() => {
    if (editingId) {
      fetchShow();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingId]);

  useEffect(() => {
    // remove scroll in body
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <div className="fixed inset-0 z-[160] flex h-full w-full items-center justify-center bg-black/50 backdrop:blur-md">
      <div className="no-scrollbar relative flex h-screen w-full flex-col overflow-y-auto overflow-x-hidden rounded-none bg-white-50 lg:flex-row lg:rounded-xl xl:h-[90vh] xl:max-h-[700px] xl:w-[85vw] xl:max-w-[1100px]">
        <div
          onClick={() => closeModalAndClear()}
          className="absolute right-2 top-2 z-50 flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-full bg-black/50 text-white-50 xl:-top-12 xl:-right-12 xl:bg-white-50 xl:text-black"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="h-6 w-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </div>

        <div className="flex w-full flex-row px-2 no-scrollbar md:gap-0 gap-4 overflow-x-auto rounded-none bg-[#eef5fb] p-8 md:px-0 pt-20 md:justify-center lg:h-full lg:w-[350px] lg:flex-col lg:justify-start lg:gap-12 lg:rounded-l-xl lg:px-16 lg:pt-16 xl:pt-32">
          <Image
            src="/logo.png"
            alt="TranzactWith"
            height={50}
            width={50}
            className="mb-4 hidden object-contain lg:block"
          />
          <OneTab
            activeTab={activeStep}
            tabName="Show Details"
            tabNumber="1"
            setActiveTab={(num: string) => setActiveStep(num)}
            allowed={!!showType}
          />
          <OneTab
            activeTab={activeStep}
            tabName="Select Products"
            tabNumber="2"
            setActiveTab={(num: string) => setActiveStep(num)}
            allowed={!!savedShow?._id}
          />
          <OneTab
            activeTab={activeStep}
            tabName="Code Installation"
            tabNumber="3"
            setActiveTab={(num: string) => setActiveStep(num)}
            allowed={savedShow?.products?.length > 0}
          />
        </div>
        <div className="no-scrollbar relative flex md:h-full h-4/5 w-full flex-col gap-6 p-8 py-5 pt-0 px-4 lg:px-8">
          <div className="h-full overflow-y-auto flex pb-16 flex-col gap-6 no-scrollbar md:pt-16 pt-0">
            <div className="w-full h-[60px] borderBottomTabs pl-4 bg-white-50 justify-between pr-6 md:flex hidden items-center absolute top-0 inset-x-0">
              <h2 className="font-bold text-lg text-gray-400">Create Show</h2>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="lucide lucide-x text-gray-400 cursor-pointer"
                onClick={() => {
                  dispatch(setSelectedVideo(undefined));
                  dispatch(setSavedShow({}));
                  closeModal && closeModal(false);
                }}
              >
                <path d="M18 6 6 18" />
                <path d="m6 6 12 12" />
              </svg>
            </div>
            {activeStep === "1" && (
              <StepOne
                saveData={(stepOneObj) =>
                  setShowData({
                    ...showData,
                    ...stepOneObj,
                    userId: userProfile?._id,
                  })
                }
                isEdit={!!editingId}
                setSelectedGoLive={(selected) => setSelectedGoLive(selected)}
                selectedGoLive={selectedGoLive}
                hasTouched={(val: boolean) => setHasTouchedDate(val)}
              />
            )}
            {activeStep === "1" && (
              <StepFour
                saveData={(stepFourObj) => {
                  setShowData({
                    ...showData,
                    ...stepFourObj,
                    durationInSeconds: 1800,
                  });
                }}
                loading={uploading}
                image={null}
              />
            )}

            {activeStep === "2" && (
              <StepTwo editingId={editingId} refreshShow={() => fetchShow()} />
            )}
            {activeStep === "3" && (
              <SnippetBlock
                showType={(type as "offsite" | "onsite") ?? "offsite"}
                streamId={savedShow?.streamID ?? ""}
              />
            )}
          </div>

          <div className="z-10 mt-auto bg-white-50 p-2 w-full flex h-max absolute bottom-0 inset-x-0 justify-between gap-6 md:gap-8 pt-6">
            <hr className="my-2 mb-0 absolute h-0.5 w-full top-0 inset-x-0 border-t-0 bg-neutral-200 opacity-100 dark:opacity-50" />
            <button
              onClick={() => prevHandler()}
              disabled={isLoading || updating || uploading}
              className="h-[40px] w-1/2 rounded-[5px] bg-[#7F82BA] py-1 px-8 text-[15px] font-medium text-white-50 md:w-[200px]"
            >
              {activeStep !== "1" ? "Back" : "Cancel"}
            </button>

            <button
              onClick={() => nextHandler()}
              disabled={isLoading || updating || uploading}
              className="h-[40px] w-1/2 rounded-[5px] bg-[#25CC89] py-1 text-[15px] font-medium text-white-50 disabled:opacity-50 md:w-[200px]"
            >
              {isLoading || updating || uploading
                ? "Saving..."
                : `${
                    activeStep === "3"
                      ? `${
                          editingId
                            ? `${
                                getShowStatus(
                                  savedShow?.start_date,
                                  savedShow?.durationInSeconds ?? 5,
                                  savedShow
                                ) === "Upcoming"
                                  ? "Go Live Later"
                                  : "Go Live"
                              }`
                            : `${
                                getShowStatus(
                                  savedShow?.start_date,
                                  savedShow?.durationInSeconds ?? 5,
                                  savedShow
                                ) === "Upcoming"
                                  ? savedShow?.mediaURL
                                    ? "Finish"
                                    : "Go Live Later"
                                  : savedShow?.mediaURL
                                  ? "Publish"
                                  : "Go Live"
                              }`
                        }`
                      : "Continue"
                  }`}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

function OneTab({
  activeTab,
  setActiveTab,
  tabNumber,
  tabName,
  allowed,
}: {
  activeTab: string;
  setActiveTab: (tabNumber: string) => void;
  tabNumber: string;
  tabName: string;
  allowed: boolean;
}) {
  return (
    <div
      onClick={() => {
        if (!allowed) {
          return;
        }
        setActiveTab(tabNumber);
      }}
      className={`flex w-max cursor-pointer flex-col items-center gap-2 text-base font-semibold leading-tight lg:w-auto lg:flex-row lg:text-xl ${
        tabNumber === activeTab ? "text-gray-600" : "text-gray-100"
      }`}
    >
      <p className="w-max">{tabName}</p>
      <div
        className={`flex h-[30px] shrink-0 w-[30px] items-center justify-center rounded-full text-xs lg:hidden ${
          tabNumber === activeTab
            ? "bg-[#104553] text-white-50"
            : "bg-gray-100 text-white-200"
        }`}
      >
        {" "}
        <span>{tabNumber}</span>
      </div>
    </div>
  );
}

export function SnippetBlock({
  streamId,
  showType,
  fromLibrary,
  closeView,
  isUpcoming,
  isEditing,
}: {
  streamId: string;
  showType: "onsite" | "offsite" | undefined;
  fromLibrary?: boolean;
  closeView?: () => void;
  isUpcoming?: boolean;
  isEditing?: boolean;
}) {
  const copyText = `<script>
    (function (w, d, s, o, f, js, fjs) {
        w[o] = w[o] || function () { (w[o].q = w[o].q || []).push(arguments) };
        js = d.createElement(s), fjs = d.getElementsByTagName(s)[0];
        js.id = o; js.src = f; js.async = 1; fjs.parentNode.insertBefore(js, fjs);
    }(window, document, 'script', '_hw', 'https://tzw-widget.herokuapp.com/widget.js'));
    _hw('init', {streamId: "${streamId}", stage: "dev" });
</script>
`;

  const [hasCopied, setHasCopied] = useState(false);

  const copySnippet = () => {
    navigator.clipboard.writeText(copyText);
    setHasCopied(true);
  };

  const copyLink = () => {
    const link =
      process.env.STAGE === "production"
        ? "http://player.tranzactwith.com/watch/" + streamId
        : `${
            process.env.NODE_ENV === "development"
              ? "http://localhost:3000/watch/" + streamId
              : "https://staging-tzw.vercel.app/watch/" + streamId
          }`;
    navigator.clipboard.writeText(link);
    setHasCopied(true);
  };

  const router = useRouter();
  const { shopId } = router.query;

  return (
    <div
      className={`flex flex-col gap-3 font-medium text-gray-300 bg-white-50 ${
        fromLibrary
          ? "lg:h-max rounded-md lg:w-4/5 w-full h-full max-w-[800px] p-8"
          : "p-0 h-max w-full pt-4"
      }`}
    >
      {showType === "onsite" ? (
        <h2 className="text-lg font-bold text-gray-600 lg:text-2xl">
          {fromLibrary
            ? isEditing
              ? "Show Updated!"
              : "Show Created!"
            : "Almost there"}
          . Install Tranzactwith widget to start engaging{" "}
          <br className="hidden lg:block" /> with your prospects and customers{" "}
        </h2>
      ) : (
        <h2 className="text-lg font-bold text-gray-600 lg:text-2xl">
          {fromLibrary
            ? isEditing
              ? "Show Updated!"
              : "Show Created!"
            : "Almost there"}
        </h2>
      )}

      <>
        <p className="font-semibold">
          {showType === "onsite" ? (
            <span>
              Paste this code snippet before <code>{`</body>`}</code>
            </span>
          ) : (
            "Copy your show link and share with your audience"
          )}
        </p>
        {showType === "onsite" ? (
          <code className="relative w-full rounded-xl p-0 font-mono text-white-50">
            <CodeSnippet language="javascript" code={copyText} />
            <span
              onClick={() => copySnippet()}
              className="absolute top-4 right-4 flex w-max cursor-pointer gap-2 self-end rounded bg-gray-400 px-4 py-2 text-base font-semibold text-white-50 transition-all duration-200 ease-in"
            >
              {hasCopied ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="lucide lucide-copy-check text-white-50"
                >
                  <path d="m12 15 2 2 4-4" />
                  <rect width="14" height="14" x="8" y="8" rx="2" ry="2" />
                  <path d="M4 16c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2h10c1.1 0 2 .9 2 2" />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="lucide lucide-copy text-white-50"
                >
                  <rect width="14" height="14" x="8" y="8" rx="2" ry="2" />
                  <path d="M4 16c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2h10c1.1 0 2 .9 2 2" />
                </svg>
              )}
              {hasCopied ? "Copied!" : "Copy"}
            </span>
          </code>
        ) : (
          <CopyLinkButton
            link={
              process.env.STAGE === "production"
                ? "http://player.tranzactwith.com/watch/" + streamId
                : `${
                    process.env.NODE_ENV === "development"
                      ? "http://localhost:3000/watch/" + streamId
                      : "https://staging-tzw.vercel.app/watch/" + streamId
                  }`
            }
            copy={() => copyLink()}
            hasCopied={hasCopied}
          />
        )}

        {!fromLibrary && (
          <div className="my-0 flex items-center justify-center gap-2 font-semibold">
            <p>Need help?</p>
            <p className="flex items-center gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#4682B4"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="m22 8-6 4 6 4V8Z" />
                <rect width="14" height="12" x="2" y="6" rx="2" ry="2" />
              </svg>{" "}
              Watch this video
            </p>
            <p className="flex items-center gap-2">
              Or{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#4682B4"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M4 19.5v-15A2.5 2.5 0 0 1 6.5 2H20v20H6.5a2.5 2.5 0 0 1 0-5H20" />
              </svg>{" "}
              Refer this guide
            </p>
          </div>
        )}
        <div className="flex mb-0 w-full items-center gap-4 text-lg font-semibold">
          <hr className="my-0 h-0.5 w-1/2 border-t-0 bg-neutral-300 opacity-100 dark:opacity-50" />
          <span>Or</span>
          <hr className="my-0 h-0.5 w-1/2 border-t-0 bg-neutral-300 opacity-100 dark:opacity-50" />
        </div>
        <div className="flex flex-col gap-3 mb-0">
          <p>
            Send special invitation to your favourite fans. Enter their email
            and we'll tell them about your show.
          </p>
          <form className="relative">
            <input
              type="email"
              name="email"
              id="email"
              placeholder="Enter email..."
              className="common-input w-full"
            />
            <button
              disabled
              className="absolute right-0 h-full w-[50px] bg-gray-700 text-white-50"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="lucide lucide-arrow-right mx-auto"
              >
                <path d="M5 12h14" />
                <path d="m12 5 7 7-7 7" />
              </svg>
            </button>
          </form>
        </div>

        {fromLibrary && (
          <div className="z-10 lg:mt-auto bottom-0 inset-x-0 relative flex justify-between gap-6 md:gap-8 mt-8">
            <hr className="my-2 mb-0 h-0.5 w-full absolute -top-8 inset-x-0 border-t-0 bg-neutral-200 opacity-100 dark:opacity-50" />
            <button
              onClick={() => router.back()}
              className="h-[45px] w-1/2 rounded-[5px] bg-[#7F82BA] py-2 px-8 text-[15px] font-medium text-white-50 md:w-[200px]"
            >
              Back
            </button>

            <button
              onClick={() =>
                isUpcoming
                  ? closeView && closeView()
                  : router.push(`/${shopId}/stream/${streamId}`)
              }
              className="[45px] w-1/2 rounded-[5px] bg-[#25CC89] py-2 text-[15px] font-medium text-white-50 disabled:opacity-50 md:w-[200px]"
            >
              {!isUpcoming ? "Go Live" : "View Show"}
            </button>
          </div>
        )}
      </>
    </div>
  );
}

export function CopyLinkButton({
  link,
  copy,
  hasCopied,
  fromLibrary,
}: {
  link: string;
  copy: () => void;
  hasCopied?: boolean;
  fromLibrary?: boolean;
}) {
  return (
    <div
      className={`linkBorder shrink-0 flex h-[50px] w-full items-center rounded-md ${
        fromLibrary ? "mb-4 mt-16" : "my-16"
      }`}
    >
      <p className="flex h-full w-[calc(100%_-_100px)] items-center justify-start rounded-l-md pl-4 text-gray-200">
        {link}
      </p>
      <button
        onClick={() => copy()}
        className="h-full w-[100px] rounded-r-md bg-tdark text-base font-semibold text-white-50"
      >
        {hasCopied ? "Copied!" : "Copy"}
      </button>
    </div>
  );
}
