// @ts-ignore
import notification from "components/common/notification";
import Image from "next/image";
import Link from "next/link";
import { useSession } from "next-auth/react";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setBusinessProfile, setUserProfile } from "redux/auth/authSlice";
import {
  useGetShopInfoQuery,
  useGetUserByEmailMutation,
} from "services/baseApiSlice";

export default function Header({
  showAuthModal,
  showDropDown,
}: {
  showAuthModal: () => void;
  showDropDown: () => void;
}) {
  const { data: session } = useSession();

  const userProfile = useSelector((state: any) => state.auth.userProfile);

  const dispatch = useDispatch();

  const [getUser, { isLoading }] = useGetUserByEmailMutation();

  const getUserByEmail = async (email: string) => {
    if (!email) return;
    await getUser({ email })
      .unwrap()
      .then((payload: any) => {
        dispatch(setUserProfile(payload));
      })
      .catch((error) => {
        notification({
          status: "error",
          message: error.message,
        });
      });
  };

  useEffect(() => {
    // @ts-ignore
    if (session && !userProfile && !isLoading) {
      getUserByEmail(session?.user?.email as string);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session, userProfile, isLoading]);

  // later on, a user may have multiple stores. Selected store ID will be saved on app store. For now we just get the first store
  const shopId = userProfile?.stores
    ? userProfile?.stores[0]?.storeId
    : undefined;
  const { data: business } = useGetShopInfoQuery(shopId, {
    skip: !shopId,
  });

  useEffect(() => {
    if (business) {
      dispatch(setBusinessProfile(business));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [business]);

  return (
    <div>
      <Link href={`/${shopId}`} className="md:ml- -ml-4 hidden">
        <Image
          src="/logo.png"
          className=""
          width={40}
          height={40}
          alt="TranzactWith"
        />
      </Link>

      {userProfile ? (
        <div
          onClick={() => showDropDown()}
          className="relative -mr-3 h-[40px] w-[40px] rounded-full"
        >
          <Image
            src={
              userProfile?.profilePictureURL?.length > 0
                ? userProfile?.profilePictureURL
                : `${session?.user?.image ?? "/default.jpeg"}`
            }
            fill
            alt="Vendor Pic"
            className="rounded-full object-cover bg-white-50 object-center"
          />
        </div>
      ) : (
        <button
          onClick={() => showAuthModal()}
          className="rounded-3xl bg-black px-8 py-2 font-semibold text-white-50"
        >
          Sign in
        </button>
      )}
    </div>
  );
}
