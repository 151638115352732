import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";
import { useSelector } from "react-redux";

export default function StudioSide({
  sideOpen,
  activeIndex,
}: {
  sideOpen: boolean;
  activeIndex: number | string;
}) {
  const router = useRouter();

  const { shopId } = router.query;

  const MenuItems = [
    {
      id: 0,
      text: "Overview",
      icon: "/assets/icons/dashboard.svg",
      to: `/${shopId}`,
    },
    {
      id: 8,
      text: "Onsite",
      icon: "/assets/icons/shows.svg",
      to: `/${shopId}/shows/onsite`,
    },
    {
      id: 1,
      text: "Offsite",
      icon: "/assets/icons/offsite.svg",
      to: `/${shopId}/shows/offsite`,
    },
    {
      id: 2,
      text: "Products",
      icon: "/assets/icons/products.svg",
      to: `/${shopId}/products`,
    },
    {
      id: 3,
      text: "Analytics",
      icon: "/assets/icons/analytics.svg",
      to: `/${shopId}/analytics`,
    },
    {
      id: 4,
      text: "Library",
      icon: "/assets/icons/library.svg",
      to: `/${shopId}/library`,
    },
  ];

  const userProfile = useSelector((state: any) => state.auth.userProfile);

  const businessProfile = useSelector(
    (state: any) => state.auth.businessProfile
  );

  return (
    <aside
      className={`no-scrollbar fixed left-0 bottom-0 z-[100] hidden h-full flex-col justify-between overflow-auto bg-[#fff] pt-[80px] transition-all duration-300 ease-in md:flex ${
        sideOpen
          ? "w-[250px]"
          : `${activeIndex === "livestream" ? "w-0" : "w-[50px]"}`
      } ${
        !userProfile?.isActive &&
        userProfile?.authType === "email" &&
        userProfile
          ? "top-[50px]"
          : "top-0"
      }`}
    >
      <div className="flex flex-col">
        <div className="flex min-h-max flex-col items-center gap-2">
          <div
            className={`relative rounded-full ${
              router?.pathname?.includes("stream") && ""
            } transition-all duration-300 ease-in ${
              sideOpen ? "h-[80px] w-[80px]" : "h-[40px] w-[40px]"
            }`}
          >
            <Image
              src={`${
                businessProfile?.storeLogoURL?.length > 0
                  ? businessProfile?.storeLogoURL
                  : `/assets/icons/defaul-store.png`
              }`}
              fill
              alt={businessProfile?.businessUsername}
              className="cursor-pointer rounded-full object-cover bg-white-50 object-center"
            />
          </div>

          <div
            className={`flex flex-col items-center gap-1 ${
              sideOpen ? "h-[50px] opacity-100" : "h-0 opacity-0"
            } overflow-hidden transition-all duration-300 ease-in`}
          >
            <span className="text-[18px] font-medium text-gray-400">
              Your store
            </span>
            <p className="max-w-[100%] break-words text-center text-[13px] text-gray-600">
              {businessProfile?.businessUsername
                ? businessProfile?.businessUsername?.substring(0, 20) +
                  `${
                    businessProfile?.businessUsername.length > 20 ? "..." : ""
                  }`
                : "my-store"}
            </p>
          </div>
        </div>

        <div
          className={`no-scrollbar mt-2 flex h-auto min-h-[50px] flex-col overflow-y-scroll transition-all duration-300 ease-in ${
            sideOpen ? "gap-2" : "gap-4"
          }`}
        >
          {MenuItems.map((item) => (
            <MenuItem
              key={item.id}
              text={item.text}
              icon={item.icon}
              isActive={item.id === activeIndex}
              sideOpen={sideOpen}
              to={item.to}
            />
          ))}
        </div>
      </div>

      <div
        className={`flex min-h-max w-full flex-col self-end bg-white-50 transition-all duration-300 ease-in ${
          sideOpen ? "gap-2" : "gap-2"
        }`}
      >
        <MenuItem
          text="Billing"
          icon="/assets/icons/wallet.svg"
          isActive={activeIndex === 5}
          sideOpen={sideOpen}
          to={`/${shopId}/billing`}
        />
        <MenuItem
          text="Settings"
          icon="/assets/icons/settings.svg"
          isActive={activeIndex === 6}
          sideOpen={sideOpen}
          to={`/${shopId}/settings`}
        />
      </div>
    </aside>
  );
}

function MenuItem({
  text,
  isActive,
  icon,
  sideOpen,
  to,
}: {
  text: string;
  isActive: boolean;
  icon: any;
  sideOpen: boolean;
  to: string;
}) {
  return (
    <Link
      href={to}
      className={`border-l-[2px] ${
        isActive
          ? "border-l-[#104553] bg-[#cecece] font-medium text-[#104553]"
          : "border-l-transparent bg-transparent text-gray-400"
      } w-full cursor-pointer py-2 hover:bg-[#104553]/20 ${
        sideOpen
          ? "flex items-start justify-start"
          : "flex items-start justify-start"
      } transition-all duration-300 ease-in`}
    >
      <div
        className={`flex items-center gap-2 px-4 transition-all duration-300 ease-in ${
          sideOpen ? "pl-4 " : "pl-2"
        }`}
      >
        {text === "Analytics" ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="#000"
            className="h-6 w-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z"
            />
          </svg>
        ) : text === "Products" ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="#000"
            className="h-7 w-7"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.75 10.5V6a3.75 3.75 0 10-7.5 0v4.5m11.356-1.993l1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 01-1.12-1.243l1.264-12A1.125 1.125 0 015.513 7.5h12.974c.576 0 1.059.435 1.119 1.007zM8.625 10.5a.375.375 0 11-.75 0 .375.375 0 01.75 0zm7.5 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
            />
          </svg>
        ) : text === "Library" ? (
          <div className="relative h-[30px] w-[30px] min-w-[30px]">
            <Image src={icon} fill className="object-contain" alt={text} />
          </div>
        ) : (
          <div className="relative h-[30px] w-[30px] min-w-[30px]">
            <Image src={icon} fill className="object-contain" alt={text} />
          </div>
        )}

        <span
          className={`overflow-hidden text-lg transition-all duration-300 ease-in ${
            sideOpen ? "w-[120px] opacity-100" : "w-0 opacity-0"
          }`}
        >
          {text}
        </span>
      </div>
    </Link>
  );
}
