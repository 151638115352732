import DropDown from "components/auth/dropdown";
import AuthModal from "components/auth/modal";
import CreateShowModal from "components/setup/createShowModal";
import Image from "next/image";
import { signOut } from "next-auth/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetAuth } from "redux/auth/authSlice";
import {
  resetShowState,
  setCreateLiveShow,
  setShowPickVideo,
  toggleSidebar,
} from "redux/show/showSlice";

import StudioSide from "./sidebar";
import TopNav from "./topbar";
import UpgradeToContinue from "components/common/upgradeModal";
import { useGetUsageQuery } from "services/baseApiSlice";
import { useRouter } from "next/router";
import Breadcrumbs from "components/common/breadCrumb";
import SelectVideo from "./selectVideo";

export default function MainDashboard({
  activeIndex,
  setSearchResults,
  data,
  searchValuePass,
  hideByDefaultSide,
  hideSearch,
  showCreateShow,
  editId,
  resetEditId,
  setRefetch,
  openDropdown,
  resetDropDown,
}: {
  openSide: (val: boolean) => void;
  activeIndex: number;
  setSearchResults: (data: any[]) => void;
  data: any;
  searchValuePass: (val: string) => void;
  hideByDefaultSide?: boolean;
  hideSearch?: boolean;
  showCreateShow?: boolean;
  editId?: string;
  resetEditId?: () => void;
  setRefetch?: (val: boolean) => void;
  openDropdown?: boolean;
  resetDropDown?: () => void;
}) {
  // const [sideOpen, setSideOpen] = useState(hideByDefaultSide ? false : true);
  const openedSidebar = useSelector((state: any) => state.show.openedSidebar);

  const [initialHideSide, setInitialHideSide] = useState(
    hideByDefaultSide ? true : false
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(resetShowState());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [showAuthModal, setShowAuthModal] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  // const [showCreate, setShowCreate] = useState(false);

  const [editingId, setEditingId] = useState(editId);

  useEffect(() => {
    if (editId) {
      setEditingId(editId);
      dispatch(setCreateLiveShow(true));
    }
  }, [editId]);

  useEffect(() => {
    if (showCreateShow) {
      dispatch(setCreateLiveShow(true));
    }
  }, [showCreateShow]);

  const userProfile = useSelector((state: any) => state.auth.userProfile);

  const [showUpgradeModal, setShowUpgradeModal] = React.useState<
    boolean | string
  >(false);

  const { currentData: usage, refetch: refetchUsage } = useGetUsageQuery(
    undefined,
    {
      refetchOnMountOrArgChange: true,
      pollingInterval: 1000 * 60 * 1, // 1 minutes
    }
  );

  const [closeShowOptions, setCloseShowOptions] = useState(false);

  useEffect(() => {
    if (openDropdown) {
      setCloseShowOptions(true);
      resetDropDown && resetDropDown();
    }
  }, [openDropdown]);

  const createLiveShow = useSelector((state: any) => state.show.createLiveShow);
  const showPickVideo = useSelector((state: any) => state.show.showPickVideo);

  return (
    <section
      className={`${
        !userProfile?.isActive &&
        userProfile?.authType === "email" &&
        userProfile
          ? "pt-[50px]"
          : ""
      }`}
    >
      <StudioSide
        key={activeIndex}
        activeIndex={activeIndex}
        sideOpen={initialHideSide ? false : openedSidebar}
      />
      <TopNav
        toggleSidebar={() => {
          setInitialHideSide(false);
          dispatch(toggleSidebar(!openedSidebar));
        }}
        activeIndex={activeIndex}
        setSearchResults={setSearchResults}
        data={data}
        searchValuePass={searchValuePass}
        showDropDown={() => setShowDropdown(!showDropdown)}
        showAuthModal={() => setShowAuthModal(!showAuthModal)}
        hideSearch={hideSearch}
      />

      {showPickVideo && (
        <SelectVideo
          close={() => dispatch(setShowPickVideo(false))}
          showCreateModal={() => [dispatch(setShowPickVideo(false)),dispatch(setCreateLiveShow(true))]}
        />
      )}
      {createLiveShow && (
        <CreateShowModal
          editingId={editingId}
          closeModal={(noRefresh: boolean) => {
            setEditingId(undefined);
            resetEditId && resetEditId();
            if (noRefresh) {
              setRefetch && setRefetch(true);
            }
            refetchUsage();
            dispatch(setCreateLiveShow(false));
          }}
        />
      )}
      {showAuthModal && <AuthModal />}
      {showDropdown && (
        <DropDown
          closeModal={() => setShowDropdown(false)}
          signOut={() => {
            dispatch(resetAuth());
            signOut({ redirect: true, callbackUrl: "/" });
            setShowDropdown(false);
          }}
        />
      )}
      {showUpgradeModal && (
        <UpgradeToContinue
          close={() => setShowUpgradeModal(false)}
          resource={showUpgradeModal as string}
        />
      )}
    </section>
  );
}

export function DropDownOptions({
  data,
  close,
}: {
  data: {
    title: string;
    desc: string;
    icon: string;
    onClick: () => void;
  }[];
  close: () => void;
}) {
  return (
    <div
      onClick={(e) => (e.currentTarget === e.target ? close() : null)}
      className="fixed inset-0 z-[200] flex h-screen w-screen justify-end pt-[9.5rem] pr-4 lg:pt-[8.9rem] lg:pr-6"
    >
      <div className="flex h-max w-max max-w-[300px] flex-col gap-4 rounded-lg bg-white-50 p-4 shadow">
        {data.map((item, i) => (
          <div
            key={i}
            onClick={() => item.onClick()}
            className="flex cursor-pointer items-center gap-4 pr-4 transition-all duration-300 ease-in hover:bg-neutral-100"
          >
            <div className="flex h-16 w-16 shrink-0 items-center justify-center rounded-lg bg-neutral-200">
              <Image
                src={item.icon}
                height={32}
                width={32}
                alt={item.title}
                className="mr-1 shrink-0"
              />
            </div>
            <div className="flex flex-col gap-0">
              <h2 className="text-lg font-semibold text-gray-800">
                {item.title}
              </h2>
              <p className="text-sm font-medium text-gray-300">{item.desc}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
