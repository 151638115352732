import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";

export default function UpgradeToContinue({
  close,
  resource,
}: {
  close: () => void;
  resource: string;
}) {
  const router = useRouter();
  const { shopId } = router.query;
  return (
    <div
      onClick={(e) => (e.currentTarget === e.target ? close() : null)}
      className="fixed inset-0 z-[200] flex h-full w-full items-center justify-center bg-black/50 backdrop:blur-md"
    >
      <div className="w-[600px] h-max p-4 rounded-3xl shadow-lg bg-white-50 flex-col flex justify-center items-center gap-4">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="42"
          height="42"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          className="lucide lucide-crown text-amber-400"
        >
          <path d="m2 4 3 12h14l3-12-6 7-4-7-4 7-6-7zm3 16h14" />
        </svg>
        <h2 className="text-tdark font-bold text-xl">Upgrade to {resource}</h2>
        <p className="text-gray-400 font-medium text-center !leading-[160%]">
          Unlock the full potential of Tranzactwith today! Upgrade now to access
          premium features that will supercharge your experience, from enhanced
          functionality to exclusive benefits
        </p>
        <Link
          href={`/${shopId}/billing#upgrade`}
          className="text-white-50 max-w-[200px] gap-2 flex justify-center items-center font-semibold text-sm bg-tdark px-8 py-2 rounded-md"
        >
          Upgrade Now
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            className="lucide lucide-crown text-amber-400"
          >
            <path d="m2 4 3 12h14l3-12-6 7-4-7-4 7-6-7zm3 16h14" />
          </svg>
        </Link>
      </div>
    </div>
  );
}

export function SuccessSubcribe({ close }: { close: () => void }) {
  return (
    <div
      onClick={(e) => (e.currentTarget === e.target ? close() : null)}
      className="fixed inset-0 z-[200] flex h-full w-full items-center justify-center bg-black/50 backdrop:blur-md"
    >
      <div className="w-[600px] h-max p-4 rounded-3xl shadow-lg bg-white-50 flex-col flex justify-center items-center gap-4">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="42"
          height="42"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          className="lucide lucide-crown text-amber-400"
        >
          <path d="m2 4 3 12h14l3-12-6 7-4-7-4 7-6-7zm3 16h14" />
        </svg>
        <h2 className="text-tdark font-bold text-xl">Subscription Success!</h2>
        <p className="text-gray-400 font-medium text-center !leading-[160%]">
          Your new plan has been activated
        </p>
        <button
          onClick={() => close()}
          className="text-white-50 max-w-[200px] gap-2 flex justify-center items-center font-semibold text-sm bg-tdark px-8 py-2 rounded-md"
        >
          Continue
        </button>
      </div>
    </div>
  );
}

export function SuccessUpdate({ close }: { close: () => void }) {
  return (
    <div
      onClick={(e) => (e.currentTarget === e.target ? close() : null)}
      className="fixed inset-0 z-[200] flex h-full w-full items-center justify-center bg-black/50 backdrop:blur-md"
    >
      <div className="w-[600px] h-max p-4 rounded-3xl shadow-lg bg-white-50 flex-col flex justify-center items-center gap-4">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="42"
          height="42"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          className="lucide lucide-crown text-amber-400"
        >
          <path d="m2 4 3 12h14l3-12-6 7-4-7-4 7-6-7zm3 16h14" />
        </svg>
        <h2 className="text-tdark font-bold text-xl">Subscription updated!</h2>
        <p className="text-gray-400 font-medium text-center !leading-[160%]">
          Your plan has been updated successfully!
        </p>
        <button
          onClick={() => close()}
          className="text-white-50 max-w-[200px] gap-2 flex justify-center items-center font-semibold text-sm bg-tdark px-8 py-2 rounded-md"
        >
          Continue
        </button>
      </div>
    </div>
  );
}

export function DeleteBeforeDowngrade({
  close,
  toDeleteInstructions,
}: {
  close: () => void;
  toDeleteInstructions: string[];
}) {
  return (
    <div
      onClick={(e) => (e.currentTarget === e.target ? close() : null)}
      className="fixed inset-0 z-[200] flex h-full w-full items-center justify-center bg-black/50 backdrop:blur-md"
    >
      <div className="w-[700px] h-max p-4 rounded-3xl shadow-lg bg-white-50 flex-col flex justify-center items-center gap-4">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="42"
          height="42"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          className="lucide lucide-crown text-amber-400"
        >
          <path d="m2 4 3 12h14l3-12-6 7-4-7-4 7-6-7zm3 16h14" />
        </svg>
        <h2 className="text-tdark font-bold text-xl">
          Action Required Before Downgrade
        </h2>
        <p className="text-gray-400 font-medium text-center !leading-[160%]">
          You have to delete some items in your store before you can downgrade
          your plan.
        </p>

        <ul className="w-full text-left max-w-[400px]">
          {toDeleteInstructions?.map((t) => (
            <li className="text-gray-400 font-medium text-left flex items-center gap-1 !leading-[160%]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="lucide lucide-dot"
              >
                <circle cx="12.1" cy="12.1" r="1" />
              </svg>
              {t}
            </li>
          ))}
        </ul>

        <button
          onClick={() => close()}
          className="text-white-50 max-w-[200px] gap-2 flex justify-center items-center font-semibold text-sm bg-tdark px-8 py-2 rounded-md"
        >
          Close
        </button>
      </div>
    </div>
  );
}

export function UpgradeComingSoon({ close }: { close: () => void }) {
  return (
    <div
      onClick={(e) => (e.currentTarget === e.target ? close() : null)}
      className="fixed inset-0 z-[200] flex h-full w-full items-center justify-center bg-black/50 backdrop:blur-md"
    >
      <div className="w-[600px] h-max p-4 rounded-3xl shadow-lg bg-white-50 flex-col flex justify-center items-center gap-4">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="42"
          height="42"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          className="lucide lucide-crown text-amber-400"
        >
          <path d="m2 4 3 12h14l3-12-6 7-4-7-4 7-6-7zm3 16h14" />
        </svg>
        <h2 className="text-tdark font-bold text-xl">Plans Activating Soon!</h2>
        <p className="text-gray-400 font-medium text-center !leading-[160%]">
          We are finishing up the final touches on our new plans. We will notify
          you when they are ready!
        </p>
        <button
          onClick={() => close()}
          className="text-white-50 max-w-[200px] gap-2 flex justify-center items-center font-semibold text-sm bg-tdark px-8 py-2 rounded-md"
        >
          Close
        </button>
      </div>
    </div>
  );
}
