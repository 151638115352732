import Image from "next/image";
import { useRouter } from "next/router";
import { useSession } from "next-auth/react";
import React from "react";
import { useSelector } from "react-redux";
import { useLogout } from "./useLogout";

export default function DropDown({
  closeModal,
  signOut,
}: {
  closeModal: () => void;
  signOut: () => void;
}) {
  const { data: session } = useSession();

  const router = useRouter();

  const userProfile = useSelector((state: any) => state.auth.userProfile);

  const businessProfile = useSelector(
    (state: any) => state.auth.businessProfile
  );

  const { logout } = useLogout();

  return (
    <div
      onClick={(e) => (e.target === e.currentTarget ? closeModal() : null)}
      className="fixed inset-0 left-0 z-[140] flex h-screen w-full items-center justify-center md:left-4 md:top-[70px] md:items-start md:justify-end"
    >
      <div
        className="no-scrollbar absolute inset-0 top-[60px] z-50 flex h-screen w-screen list-none flex-col items-start divide-gray-100 self-end overflow-y-auto rounded-md bg-white-50 md:px-0 md:pl-0 pt-[20px] pb-6 text-base shadow-lg md:right-8 md:-top-3 md:left-[unset] md:h-[300px] md:w-[275px] md:pt-0 md:pb-12"
        id="dropdown"
      >
        <div
          onClick={() => closeModal()}
          className="bg-white absolute top-2 right-2 flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-full md:hidden"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="#000"
            className="h-8 w-8"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </div>
        {/* account section */}
        <div className="flex w-full gap-4 bg-[#f5f6fa] py-3 pl-6">
          <div className="relative h-[40px] w-[40px] min-w-[40px] rounded-full">
            <Image
              src={
                userProfile?.profilePictureURL ??
                `${session?.user?.image ?? "/default.jpeg"}`
              }
              fill
              alt="Vendor"
              className="rounded-full object-cover bg-white-50 object-center"
            />
          </div>
          <div className="flex w-full flex-col gap-2 text-xs font-medium">
            <span className="w-full break-all text-[0.875rem] font-bold">
              {userProfile?.firstName + " " + (userProfile?.lastName ?? "")}
            </span>
            <p
              onClick={() => closeModal()}
              className="cursor-pointer text-[12px] font-medium text-gray-200"
            >
              {userProfile?.email}
            </p>
          </div>
        </div>
        <div className="hr-color mt-0 mb-2 h-[1px] min-h-[1px] w-full min-w-[100%]"></div>
        <ul className="ml-0 mb-1 w-full py-1 pl-0" aria-labelledby="dropdown">
          <li
            onClick={() => {
              router.push(`/${businessProfile?._id}/settings`);
            }}
            className="flex items-center gap-2 pl-6 pr-12 text-gray-700 hover:text-blue"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="h-6 w-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
              />
            </svg>

            <span className="block cursor-pointer py-3 text-sm">
              View Profile
            </span>
          </li>
          <li
            onClick={() => {
              router.push(`/${businessProfile?._id}/settings`);
            }}
            className="flex items-center gap-2 pl-6 pr-12 text-gray-700 hover:text-blue"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="h-6 w-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 011.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.56.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.893.149c-.425.07-.765.383-.93.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 01-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.397.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 01-.12-1.45l.527-.737c.25-.35.273-.806.108-1.204-.165-.397-.505-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.107-1.204l-.527-.738a1.125 1.125 0 01.12-1.45l.773-.773a1.125 1.125 0 011.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
              />
            </svg>

            <span className="block cursor-pointer py-3 text-sm">
              Account Settings
            </span>
          </li>
          <li
            onClick={() => {
              router.push(`/${businessProfile?._id}/billing`);
            }}
            className="flex items-center gap-2 pl-6 pr-12 text-gray-700 hover:text-blue"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              className="lucide lucide-wallet"
            >
              <path d="M21 12V7H5a2 2 0 0 1 0-4h14v4" />
              <path d="M3 5v14a2 2 0 0 0 2 2h16v-5" />
              <path d="M18 12a2 2 0 0 0 0 4h4v-4Z" />
            </svg>

            <span className="block cursor-pointer py-3 text-sm">Billing</span>
          </li>

          <div className="hr-color mt-2 mb-2 h-[1px] min-h-[1px] w-full"></div>
          <li
            onClick={() => {
              logout();
            }}
            className="flex items-center gap-2 pl-7 pr-12 text-gray-700 hover:text-blue"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              className="lucide lucide-log-out"
            >
              <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
              <polyline points="16 17 21 12 16 7" />
              <line x1="21" x2="9" y1="12" y2="12" />
            </svg>

            <span className="block cursor-pointer py-3 text-sm">Logout</span>
          </li>
        </ul>
      </div>
    </div>
  );
}
