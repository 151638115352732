export const getShowStatus = (
  startDate: string,
  durationInSeconds: number,
  show: any,
  isFilter = false
) => {
  if (isFilter) {
    return "All";
  }
  // possible status: Upcoming, Live, Was Live compare against current date
  const currentDate = new Date();
  const toDate = new Date(startDate);

  const elapsedMins = Math.floor(
    (currentDate.getTime() - toDate.getTime()) / 1000 / 60
  );

  if (
    elapsedMins >= 15 &&
    !show?.hasEnded &&
    show?.streamStatus === "started"
  ) {
    return "Was Live";
  }

  if (show?.hasEnded) {
    return "Was Live";
  }

  if (show?.streamStatus === "done") {
    return "Was Live";
  }
  if (currentDate > toDate && show?.streamStatus === "started") {
    // find if the show is live: current date is between start date and end date
    return "Live";
  }

  if (
    show?.streamStatus === "started" &&
    show?.streamStatus !== "done" &&
    currentDate < new Date(toDate.getTime() + 180 * 1000)
  ) {
    return "Live";
  }

  if (elapsedMins >= 15 && !show?.hasEnded) {
    return "Was Live";
  }

  if (currentDate > new Date(toDate.getTime() + 15 * 60 * 1000)) {
    return "Was Live";
  }
  if (new Date(new Date().getTime() + 5 * 60000) < new Date(startDate)) {
    return "Upcoming";
  }
  // if 30 mins have elapsed from toDate, show status is not started or done, then show is late and hence Was Live
  if (
    currentDate > new Date(toDate.getTime() + 15 * 60 * 1000) &&
    show?.streamStatus !== "started" &&
    show?.streamStatus !== "done"
  ) {
    return "Was Live";
  }
  return "Live";
};

export function kFormater(num: number) {
  const formatter = Intl.NumberFormat("en", { notation: "compact" });
  return formatter.format(num);
}

export const dateInCurrentTimeZone = (date: Date) => {
  return date;
};

export function formatDuration(timestamp: string): string {
  const currentTime = new Date();
  const elapsedTime = currentTime.getTime() - new Date(timestamp).getTime();
  const seconds = Math.floor(elapsedTime / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);
  const months = Math.floor(weeks / 4);
  const years = Math.floor(months / 12);

  if (seconds < 60) {
    if (seconds < 0) {
      return "0s";
    }
    return `${seconds}s`;
  } else if (minutes < 60) {
    return `${minutes}m`;
  } else if (hours < 24) {
    return `${hours}h`;
  } else if (days < 7) {
    return `${days}d`;
  } else if (weeks < 4) {
    return `${weeks}w`;
  } else if (months < 12) {
    return `${months}m`;
  } else if (years < 10) {
    return `${years}y`;
  } else {
    return "10y+";
  }
}
