import React, { useEffect, useState } from "react";
// @ts-ignore

export interface ShowInterface {
  title: string;
  description: string;
  date: string;
  eventType: "liveparty" | "liveshow";
  showPrivacy: "public" | "private";
  showOnlineUsers: "yes" | "no";
  allowChat: "yes" | "no";
  canUseGuestMode: "yes" | "no";
  image: File;
  videoLink: string;
}

export interface IProduct {
  _id: string;
  url?: string;
  name: string;
  price: {
    $numberDecimal: string;
  };
  imageURL: string;
  productUrl: string;
}

// @ts-ignore
import notification from "components/common/notification";
// @ts-ignore
import { Spinner } from "components/common/spinner";
// @ts-ignore
import useDebounce from "components/common/useDebounce";
// @ts-ignore
import { thousandSeparator } from "components/routes/livestream/live";
// @ts-ignore
import { ShopProfile } from "components/settings/personal";
import Image from "next/image";
import Link from "next/link";
import { useDispatch, useSelector } from "react-redux";
import { setSavedShow } from "redux/show/showSlice";
import { setCompletedSteps, setProducts } from "redux/show/showSlice";
import {
  useGetAllProductsQuery,
  useUpdateShowInfoMutation,
} from "services/baseApiSlice";

const page = 1;

export default function StepTwo({
  refreshShow,
  editingId,
}: {
  refreshShow: () => void;
  editingId: string | undefined;
}) {
  const dispatch = useDispatch();
  const existingProducts: IProduct[] = useSelector(
    (state: any) => state.show.products
  );

  const completedSteps = useSelector((state: any) => state.show.completedSteps);
  const products = useSelector((state: any) => state.show.products);

  const userProfile = useSelector((state: any) => state.auth.userProfile);

  // later on, a user may have multiple stores. Selected store ID will be saved on app store. For now we just get the first store
  const shopId = userProfile?.stores
    ? userProfile?.stores[0]?.storeId
    : undefined;

  const { data, isFetching } = useGetAllProductsQuery(
    {
      page,
      shopId,
    },
    {
      skip: !shopId,
    }
  );

  useEffect(() => {
    if (data) {
      dispatch(setProducts(data.products));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const savedShow = useSelector((state: any) => state.show.savedShow);

  useEffect(() => {
    if (savedShow?.products?.length > 0) {
      if (!completedSteps.includes(2)) {
        dispatch(setCompletedSteps([...completedSteps, 2]));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedShow?.products, completedSteps]);

  const [searchValue, setSearchValue] = useState("");
  const debouncedValue = useDebounce(searchValue, 500);

  const [searchResults, setSearchResults] = useState<any[]>([]);

  // search
  useEffect(() => {
    // given allKnacks array, search for all elements with name field contains the debounced value - store in results
    if (data?.products && debouncedValue?.length > 0) {
      const results: any = [];
      data?.products?.forEach((pr: any) => {
        if (pr.name.toLowerCase().includes(debouncedValue.toLowerCase())) {
          results.push(pr);
        }
      });
      if (results.length > 0) {
        setSearchResults(results);
      } else {
        setSearchResults([]);
      }
    }

    if (debouncedValue.length === 0) {
      setSearchResults([]);
    }
  }, [debouncedValue, data]);

  return (
    <div className="no-scrollbar flex md:h-[80vh] h-auto w-full gap-8 overflow-y-auto pr-0 pt-4 md:w-full">
      <div className="flex w-full flex-col gap-4">
        <div className="mt-4 flex flex-col justify-between gap-4 md:flex-row md:gap-0">
          {savedShow?.products?.length > 0 && (
            <div className="mt-8 hidden flex-col gap-2 md:mt-0">
              <p className="font-medium ">
                Selected {savedShow?.products?.length ?? 0}{" "}
                {savedShow?.products?.length === 1 ? "product" : "products"}{" "}
              </p>
            </div>
          )}
          {products?.length === 0 && !isFetching && (
            <div className="mt-8 flex w-full h-[600px] my-auto flex-col items-center justify-center gap-6 text-center md:mt-0">
              <Image
                src="/assets/icons/products-zero.svg"
                height="70"
                width="70"
                alt="Add products"
                className="object-contain"
              />
              <p className="flex flex-col gap-4 text-lg font-semibold">
                This store has no products. Please add products to continue.{" "}
                <br />
                <Link
                  href={`/${shopId}/products`}
                  className="mx-auto flex w-max items-center gap-2 self-end rounded-[10px] bg-[#104553] px-4 py-2 text-[18px] font-medium text-white-50"
                >
                  Add Products
                </Link>
              </p>
            </div>
          )}
        </div>
        {existingProducts?.length > 0 && (
          <form className="relative -mt-6 flex h-max w-full items-center gap-2 pl-0">
            <input
              type="text"
              name="search"
              id="search"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              placeholder="Search Products"
              className="search-input h-[45px] w-full rounded-3xl bg-[#E8E8E8] px-4 pr-8 text-sm font-medium text-gray-300 focus:border-gray-300 focus:outline-gray-300 md:px-8 md:py-2 md:text-base"
            />

            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={3}
              stroke="#404040"
              className="absolute right-4 h-6 w-6"
              role="button"
              type="submit"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
              />
            </svg>
          </form>
        )}
        {searchValue.length > 0 && searchResults.length === 0 && (
          <p className="text-md text-gray-300">{`No products found for name: ${searchValue}`}</p>
        )}
        <div className="flex w-full flex-col gap-[15px] md:w-full md:flex-row md:flex-wrap">
          {searchValue.length > 0 &&
            searchResults.length > 0 &&
            existingProducts?.length > 0 &&
            searchResults?.map((pr) => <OneRow key={pr._id} product={pr} />)}
          {isFetching ? (
            <div className="flex h-[400px] w-full items-center justify-center">
              <Spinner />
            </div>
          ) : searchValue.length === 0 ? (
            existingProducts?.map((pr) => <OneRow key={pr._id} product={pr} />)
          ) : null}
        </div>
      </div>
    </div>
  );
}

export function OneRow({
  product,
  fromSelect,
  addToSelected,
  removeFromSelected,
  selectedProductIds,
}: {
  product: IProduct;
  fromSelect?: boolean;
  addToSelected?: (productId: string) => void;
  removeFromSelected?: (productId: string) => void;
  selectedProductIds?: string[];
}) {
  const [selected, setSelected] = useState(false);

  const dispatch = useDispatch();
  const savedShow = useSelector((state: any) => state.show.savedShow);
  const [updateShowProducts, { isLoading }] = useUpdateShowInfoMutation();

  const toggleProduct = async (productId: string, action: string) => {
    if (isLoading) return;
    if (!savedShow?._id && !fromSelect) {
      notification({
        status: "error",
        message: "You have to finish step 1 first",
      });
      return;
    }

    if (selectedProductIds?.includes(product._id)) {
      removeFromSelected && removeFromSelected(productId);
    } else {
      addToSelected && addToSelected(productId);
    }

    if (fromSelect) {
      return;
    }

    const obj = {
      products:
        action === "add"
          ? savedShow?.products?.length > 0
            ? [...savedShow.products, productId]
            : [productId]
          : savedShow?.products?.filter((id: string) => id !== productId),
    };
    const showId = savedShow._id;
    await updateShowProducts({ obj, showId })
      .unwrap()
      .then((payload) => {
        if (action === "add") {
          setSelected(true);
        } else {
          setSelected(false);
        }
        dispatch(
          setSavedShow({
            ...savedShow,
            products: payload.products,
          })
        );
      })
      .catch((error) => {
        console.log(error);
        notification({
          status: "error",
          message: "An error occured while updating show products",
        });
      });
  };

  const businessProfile = useSelector(
    (state: any) => state.auth.businessProfile
  ) as ShopProfile;

  useEffect(() => {
    setSelected(
      savedShow?.products?.filter((id: string) => id === product?._id).length >
        0
    );
  }, [product?._id, savedShow]);

  return (
    <div className="relative flex w-full max-w-[400px] items-center gap-2 border-b-[1px] border-gray-300 py-2 px-2 md:max-w-full">
      <div className="relative h-[50px] w-[50px] min-w-[50px] rounded-full">
        <Image
          src={
            product?.imageURL?.length > 0
              ? product?.imageURL
              : "https://res.cloudinary.com/dpnbddror/image/upload/v1676631839/product-icon-png-4_rnu19s.png"
          }
          alt={product?.name}
          fill
          className="rounded-full object-cover bg-white-50 object-center"
        />
      </div>

      <div className="flex w-full flex-col gap-2 font-semibold text-gray-400">
        <p className="w-4/5 break-all text-[18px]">{product?.name}</p>
        <span className="text-[14px]">
          {businessProfile?.storeCurrency?.split("-")[0] ?? "$"}{" "}
          {thousandSeparator(product?.price?.$numberDecimal)}
        </span>
      </div>
      <input
        checked={
          fromSelect ? selectedProductIds?.includes(product._id) : selected
        }
        onChange={() => {
          toggleProduct(product._id, selected ? "remove" : "add");
        }}
        type="checkbox"
        name="selected"
        id="selected"
        disabled={isLoading}
        className="absolute right-2 h-5 w-5 accent-[#104553] disabled:accent-slate-400"
      />
    </div>
  );
}
